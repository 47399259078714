import { textAlign } from "@mui/system"
import { Paper } from "@mui/material"
const PrivacyPolicy=()=>{
return <div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center",padding:5,textAlign:"right",direction:"rtl"}}>
    <Paper sx={{padding:4}} elevation={3}>
    <h1>بيان الخصوصية</h1>
    <section>
        <h2>نوع البيانات التي يتم جمعها</h2>
        <p>نقوم بجمع البيانات الديموغرافية للمستخدم مثل الاسم، العمر، المرحلة الدراسية، الجنس، ومكان الإقامة، والبريد الإلكتروني، ورقم الهاتف.</p>
        <p>نقوم أيضًا بجمع معلومات عن سلوك المستخدم مثل الاختبارات التي تصفحها، الاختبارات التي أكملها، معدل إكمال الاختبارات، معدل النجاح بكل سؤال من أسئلة الاختبار، ومعلومات عن الروابط التي يضغط عليها، والمدة الزمنية التي يقضيها في تصفح الموقع.</p>
        <p>قد نجمع أيضًا معلومات تقنية مثل عنوان بروتوكول الإنترنت (IP)، بيانات المتصفح، أنظمة التشغيل، ونوع الجهاز المستخدم، وموقع البيانات الجغرافي التقريبي.</p>
    </section>

    <section>
        <h2>كيفية استخدام البيانات</h2>
        <p>تستخدم البيانات الديموغرافية لأغراض دعائية وتسويقية، وتحليل شرائح المستخدمين واهتماماتهم، وإجراء البحوث والدراسات الإحصائية.</p>
        <p>تستخدم بيانات التصفح لإعداد تقارير لصناع المحتوى حول أداء اختباراتهم ومصادر المستخدمين، وتحسين تجربة المستخدم، وتخصيص المحتوى والإعلانات.</p>
        <p>قد نستخدم أيضًا بياناتك لأغراض أخرى مثل:</p>
        <ul>
            <li>الامتثال للقوانين واللوائح المعمول بها</li>
            <li>منع الاحتيال والأنشطة غير المشروعة</li>
            <li>تحسين وتطوير منتجاتنا وخدماتنا</li>
            <li>الاتصال بك بشأن التحديثات والإعلانات والعروض الترويجية</li>
            <li>تقديم الدعم الفني والمساعدة للمستخدمين</li>
        </ul>
    </section>

    <section>
        <h2>مشاركة البيانات</h2>
        <p>قد نشارك بياناتك مع أطراف ثالثة في الحالات التالية:</p>
        <ul>
            <li>مع موردي الخدمات الذين يقدمون خدمات نيابة عنا (مثل مزودي خدمات البريد الإلكتروني أو التحليلات أو الدعم الفني)</li>
            <li>عند بيع أو نقل جزء من أعمالنا أو أصولنا</li>
            <li>للامتثال لطلبات الإفصاح القانونية أو أوامر المحكمة</li>
            <li>لحماية حقوقنا أو ممتلكاتنا أو أمننا</li>
            <li>مع شركائنا التجاريين أو الإعلانيين لأغراض تسويقية أو إعلانية (مع موافقتك)</li>
        </ul>
    </section>

    <section>
        <h2>حقوق المستخدم</h2>
        <p>لديك الحق في الوصول إلى البيانات الشخصية التي نحتفظ بها عنك، وطلب تصحيحها أو حذفها في بعض الحالات. يمكنك أيضًا طلب حظر استخدام بياناتك لأغراض التسويق المباشر.</p>
        <p>لممارسة هذه الحقوق، يرجى التواصل معنا باستخدام معلومات الاتصال المقدمة في هذه الصفحة. قد نطلب منك تقديم إثبات للهوية لحماية خصوصيتك.</p>
    </section>

    <section>
        <h2>ملفات تعريف الارتباط وتقنيات التتبع الأخرى</h2>
        <p>نستخدم ملفات تعريف الارتباط وتقنيات تتبع أخرى مثل رمز التعقب الإحصائي وأدوات التحليل الويب لجمع معلومات عن كيفية استخدامك لموقعنا واهتماماتك التصفحية. يمكنك إدارة إعدادات ملفات تعريف الارتباط من خلال إعدادات متصفحك.</p>
    </section>

<section>
    <h2>أمن البيانات</h2>
    <p>نتخذ إجراءات أمنية صارمة لحماية بيانات المستخدمين من الوصول غير المصرح به أو التعديل أو الإفصاح أو الفقدان العرضي. نستخدم تقنيات التشفير وبروتوكولات الأمان القياسية لحماية البيانات أثناء نقلها وتخزينها. كما نقوم بتدريب موظفينا على ممارسات أمن البيانات الجيدة.</p>
</section>

<section>
    <h2>التحديثات</h2>
    <p>جميع التحديثات على بيان الخصوصية هذا يتم عرضها ضمن هذه الصفحة. يجب على المستخدمين الاطلاع بشكل دوري على هذه الصفحة للاطلاع على أي تغييرات. في حالة التغييرات الجذرية، سيتم إرسال بريد إلكتروني إلى عناوين البريد الإلكتروني المستخدمة لتسجيل الدخول.</p>
</section>

<section>
    <h2>جهات الاتصال</h2>
    <p>للتواصل معنا بشأن أي أسئلة أو مخاوف متعلقة بخصوصية بياناتك، يرجى زيارة صفحة "تواصل معنا" على موقعنا.</p>
</section>
 
 </Paper>
 </div>
}

export default PrivacyPolicy