import React, { useState ,useEffect} from 'react';
import { Button,TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { signIn } from '../API/user';
import {toast} from "react-hot-toast"
import TelegramLoginButton from 'telegram-login-button'
import { signInWithTelegram } from '../API/telegramLogin';
import { GoogleLogin } from '../API/googleLogin';
import GoogleButton from 'react-google-button'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: 1,
      width: '25ch',
    },
  },
}));


const USERNAME="اسم المستخدم"
const EMAIL="الإيميل"
const PASSWORD="كلمة المرور"
const SIGNIN="تسجيل الدخول"
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const SignInForm = ({redirect}) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error,setError]=useState("")

 
  const handleSignIn = async() => {
   if(username.length ==0 || !validateEmail(username)){
    setError("يرجى إدخال بريد إلكتروني بصيغة صحيحة")
    return;
   }
   if(password.length==0){
    setError("يرجى إدخال كلمة المرور")
    return;
   }
   setError("")
    try{
       if(await signIn(username,password)){
        redirect();
       }
     
      
    }catch(err){
     toast.error(err.message)
    }
  };

  const handleTelegramSignIn=async(user)=>{
   
      try{
         if(await signInWithTelegram(user)){
          redirect();
         }
       
        
      }catch(err){
       toast.error(err.message)
      }
  }

  const handleGoogleLogIn=()=>{
    GoogleLogin()
    /*try{
      if(await  ){
       redirect();
      }
    
   }catch(err){
    toast.error(err.message)
   }*/
  }

  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
    <form className={classes.form}>
      <TextField
        label={EMAIL}
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label={PASSWORD}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error !="" &&<div style={{color:"red"}}>{error}</div>}
      <div>نسيت كلمة المرور ؟ قم بتغيير كلمة المرور <Link to="/resetpassword">هنا</Link></div>
      <Button variant="contained" color="primary" onClick={handleSignIn}>
        {SIGNIN}
      </Button>
    </form>
    <div className='Hello' style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start",padding:12,margin:12,borderRadius:3,border:"1px solid grey"}}>
    <div style={{margin:3}}>يمكنك تسجيل الدخول عبر</div>
    <TelegramLoginButton
    
    botName="Ekhtibar_bot"
    dataOnauth={handleTelegramSignIn}
  />
  <div class="google-button" style={{display:"none"}}>
  <GoogleButton
   
   onClick={handleGoogleLogIn}

/></div>
    </div>
    
    </div>
  );
  
};

export default SignInForm;