import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"

export const saveProgress=async(progress)=>{
   
 try{
   await axios.post(serverURL+"/progress/",{
    progress
    },{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
        }
    })

 }catch(err){
   
 }
}
export const loadProgress=async()=>{
    try{
       const {data}= await axios.get(serverURL+"/progress/",{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
        }
       })
       
       return data
      }catch(err){
        
      }
}