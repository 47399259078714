import { getFavorites } from "../API/favorite"
import { useState,useEffect } from "react"
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Typography,IconButton } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import { like,dislike } from '../API/quiz';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { addToFavorite,removeFromFavorite } from '../API/favorite';
import toast from "react-hot-toast"
import ShareModal from "../components/ShareModal";
function Favorite({quiz,user,setQuiz,setOpen,setSQuiz}){
  let distanation="quiz"
  const navigator=useNavigate();
    return (
      <Card  className='quiz-thumbnail-container'>
  
        <CardHeader
          className="quiz-thumbnail"
          onClick={()=>{
            navigator("/"+distanation+"/"+quiz.id)
         }} sx={{ maxWidth: 345 }}
          title={quiz.title}
          subheader={quiz.created_at}
        >
          
        </CardHeader>
      
        <CardContent 
        className="quiz-thumbnail"
        
        onClick={()=>{
          navigator("/"+distanation+"/"+quiz.id)
      }} sx={{ maxWidth: 345 }}>
        {quiz.imageThumbnail !="" && <img width={"100%"} src={quiz.imageThumbnail}/>}
          <Typography variant="body2" color="text.secondary">
           {quiz.description}
          </Typography>
        </CardContent>
  
  
  
        <CardActions >
         
          <IconButton onClick={()=>{
            setSQuiz(quiz.id)
            setOpen(true)
          }
          }  aria-label="share">
            <ShareIcon />
          </IconButton>
         <div style={{display:'flex',gap:10,alignItems:"center"}}>
          < VisibilityIcon/>
          {quiz.views?quiz.views:0}
         </div>
  
         <div onClick={async()=>{
          if(!user || !user.logged) return
         const data=await like(quiz.id)
         setQuiz((old)=>{
          let newArr=[...old]
          let item= newArr.find(item=>item.id==quiz.id)
          item.likes_count=data.likes_count
          item.dislikes_count=data.dislikes_count
          item.liked=data.liked
          item.disliked=data.disliked
          return newArr
         })
        
         }} className="like-icon" style={{display:'flex',gap:10,alignItems:"center"}}>
          < ThumbUpIcon style={{color:quiz.liked?"rgb(20,150,200)":"rgb(200,200,200)"}}/>
          {quiz.likes_count?quiz.likes_count:0}
         </div>
         <div onClick={async()=>{
    if(!user || !user.logged) return
         const data =await dislike(quiz.id)
         setQuiz((old)=>{
          let newArr=[...old]
          let item= newArr.find(item=>item.id==quiz.id)
          item.likes_count=data.likes_count
          item.dislikes_count=data.dislikes_count
          item.liked=data.liked
          item.disliked=data.disliked
          return newArr
         })
         }}  className="like-icon" style={{display:'flex',gap:10,alignItems:"center"}}>
          < ThumbDownIcon style={{color:quiz.disliked?"rgb(20,150,200)":"rgb(200,200,200)"}}/>
          {quiz.dislikes_count?quiz.dislikes_count:0}
         
         </div>
      
      {quiz.favorite===1 &&<FavoriteIcon
       onClick={async()=>{
        try{
           await removeFromFavorite(quiz.id)
           toast.success("تم إزالة الإختبار  من قائمة المفضلة")
           setQuiz((old)=>{
            return old.filter(item=>item.id !=quiz.id)
           })
        }catch(err){
             toast.error("حدث خطأ أثناء إزالة الإختبار من المفضلة")
        }
     }}
       className="fav-icon"
       />}
        </CardActions>
        
      </Card>
    );
}
const FavoritesPage=({user})=>{
    const [arr,setArr]=useState([])
    const [open,setOpen]=useState(false)
    const [squiz,setSQuiz]=useState(0)
    const loadFavorites=async()=>{
        try{
          let data=await getFavorites();
        
          setArr(data);
        }catch(err){

        }
    }
    useEffect(()=>{
       loadFavorites();
    },[])
  return <>
   <ShareModal quiz_id={squiz} {...{open,setOpen}}/>
   
  <div style={{display:"flex",gap:10,flexWrap:"wrap",flexDirection:"row-reverse",padding:10,alignItems:"center",justifyContent:"center"
  }}>
  {arr && arr.map(quiz=>{
    return <Favorite setQuiz={setArr} {...{quiz,user,setOpen,setSQuiz}}/>
  })}
  </div>
  </>
}

export default FavoritesPage