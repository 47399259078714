import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SelectComp({label,value,handleChange,options}){
    return <FormControl fullWidth>
         <InputLabel id={"demo-simple-select-label-"+label}>{label}</InputLabel>
         <Select
           labelId={"demo-simple-select-label-"+label}
           id={"demo-simple-select-"+label}
           value={value}
           label={label}
           onChange={handleChange}
         >
    
           {options.map(item=>{
             return <MenuItem value={item.value}>{item.label}</MenuItem>
           })}
         </Select>
       </FormControl>
 }

 export default SelectComp