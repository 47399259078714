import axios from "axios"
import { updateToken } from "../util/token"
const serverURL =process.env.REACT_APP_SERVER_URL 

export const signUp=async(password,email,username,captchaValue)=>{
  try{
   const {data}=await axios.post(serverURL+"/users/signup",{
    password,email,username,captchaValue
   })
   if(data && data.token){
 
    updateToken(data)
    return true
   
  }
   return false
  }catch(err){
    let {data}=err.response
    throw new Error(data.msg)
  }
}
export const signIn=async(username,password)=>{
    
  try{
  const {data}=await axios.post(serverURL+"/users/signin",{
       username,password
  })

  if(data && data.token){
    updateToken(data)
    return true
  }
  return false
  }catch(err){
console.log(err);
   //let {data}=err.response
   throw new Error("error")
  }
}

export const signOut=async()=>{
    try{

    }catch(err){
      
    }
}
export const signContract=async(details)=>{
  try{
    const {data} = await axios.post(serverURL+"/users/sign",details,{
      headers:{
         Authorization:window.localStorage.getItem("usertoken")
      }
     })
    return data
  }catch(err){
    throw new Error("Error signing contract")
  }
}
export const getTokenData=async(token)=>{
  try{
   const {data}=await axios.post(serverURL+"/users/tokendata",
    {token}
   )
   return data
  }catch(err){
   return {
    logged:false,
    type:""
  }
  }
}

export const activateUser=async(code)=>{
  try{
    const {data} = await axios.post(serverURL+"/users/activateuser",{code},
      {
        headers:{
           Authorization:window.localStorage.getItem("usertoken")
        }
       }
    )
  return data
  }catch(err){
   throw new Error("")
  }
}
export const sendPasscode=async(email)=>{
 try{
  const {data} =await axios.post(serverURL+"/users/sendpasscode",{email});
  return data
 }catch(err){
  let msg=err.response.data.msg
  throw new Error(msg)

 }
}

export const changePassword=async(passcode,new_password)=>{
  try{
     const {data}=await axios.post(serverURL+"/users/changepassword",{
      passcode,new_password
     })
     return data
  }catch(err){
   let msg=err.response.data.msg
   throw new Error(msg);
  }
}

export const sendAnotherCode=async()=>{
  try{
    const {data} = await axios.post(serverURL+"/users/sendanotheracode",{},
      {
        headers:{
           Authorization:window.localStorage.getItem("usertoken")
        }
       }
    )
  return data
  }catch(err){
    let {data}=err.response
   throw new Error(data.msg)
  }
}