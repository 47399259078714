import { Button ,Box,Stack} from "@mui/material"
import { isValidImg } from "../../../util/images"
import wrongURL from "../../../images/wrongURL.jpg"

function Question({question}){
    return <Box sx={{m:1,p:1,border:"1.5px solid #00A2E8"}}>
        {question.imageURI !=="" && isValidImg(question.imageURI) && <img style={{width:"400px",maxWidth:"90%",borderRadius:10}} src={question.imageURI} />}
        {question.imageURI !=="" && !isValidImg(question.imageURI) && <img style={{width:"400px",maxWidth:"90%",borderRadius:10}} src={wrongURL} />}
      
        
        <Box sx={{border:"1px dashed black",p:1}}>{question.statment.trim()===""?"نص السؤال":question?.statment}</Box>
        <Stack spacing={1} sx={{m:1}}>
        {question?.options?.map((item,inx)=>{
          if(inx<2){
            
             return <Button 
            sx={{fontFamily:"cocon-next-arabic"}}
            
            color={item.trim()===""?"error":question.correct===inx?"success":"primary"} variant="contained">{item.trim() !=""?item:inx==0?"الخيار الأول إلزامي":"الخيار الثاني إلزامي"}</Button>
          }else{
            if(item.trim() !==""){
              return <Button 
              sx={{fontFamily:"cocon-next-arabic"}}
              
              color={question.correct===inx?"success":"primary"} variant="contained">{item}</Button>
            }else return <></>
          }
           
        })}
        </Stack>
        {question.reasoning !=="" && <Box sx={{border:"2px dashed black",padding:2}}>
        💡{question.reasoning}💡
          </Box>}
    
    </Box>
}

export default Question