import { isValidImg } from "../../../util/images"
import CardComp from "../../../components/CardComp"
import { TextField,Box,Paper} from "@mui/material"

function RenderDetailsPage({quiz,setQuiz}){
    return <div style={{
      width:"100%",
      height:"80vh",
      display:"flex",
      alignItems:"flex-start",
      justifyContent:"space-around"
      }}>
        <Paper elevation={3}  style={{padding:6,margin:6,width:"100%",height:"100%",backgroundColor:"rgb(220,230,255)"}}>
              <h1>النتيجة</h1>
              <div style={{fontSize:24}}>كيف سيبدو الإختبار على المنصة</div>
              <div style={{
        alignItems:"center",
        justifyContent:"center",
        display:"flex",
        flexWrap:"wrap",
        padding:"20px",
        gap:"20px",
        flexDirection:"row-reverse"
    }}>
                <CardComp quiz={quiz}/>
                
             </div>
  
         </Paper>
  
        <Paper elevation={3} style={{padding:6,margin:6,width:"100%",height:"100%",backgroundColor:"rgb(220,255,230)"}}>
            <h1>المحرر</h1>
            <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
        display:"flex",
        flexDirection:"column"
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
      error={quiz?.title?.trim().length<4}
      helperText={quiz?.title?.trim()?.length<4?"عنوان الإختبار يجب أن يكون أربعة حروف على الأقل":""}
      onChange={(e)=>{
        setQuiz({...quiz,title:e.target.value})
      }} value={quiz.title}   style = {{width: "95%",direction:"rtl",textAlign:"right"}} label="عنوان الااختبار" variant="outlined" fullWidth />
      <TextField 
      error={quiz?.description?.trim().length<50 || quiz.description?.trim()?.length>150}
      helperText={(quiz?.description?.trim().length<50 || quiz.description?.trim()?.length>150)?"وصف الإختبار يجب أن يكون بين 50 إلى 150 حرف":""}
      
      onChange={(e)=>{
         setQuiz({...quiz,description:e.target.value})
      }}  value={quiz.description}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="وصف الاختبار" variant="outlined" fullWidth  />
      <TextField 
      error={!isValidImg(quiz?.imageThumbnail)}
      helperText={!isValidImg(quiz?.imageThumbnail)?"يرجى إضافة صورة للإختبار":""}
      onChange={(e)=>{
         setQuiz({...quiz,imageThumbnail:e.target.value})
      }}  value={quiz?.imageThumbnail} style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="صورة الإختبار" variant="outlined" fullWidth />
    
     {!isValidImg(quiz?.imageThumbnail) &&<div style={{width:"100%"}}>
      راجع الدرس الثاني من مركز التدريب
       
      </div>}
    </Box>
  
        </Paper>
       
    </div>
}

export default RenderDetailsPage