import { useState,useEffect } from "react"
import { getTeachersList ,updateTeacherActivity} from "@api/admin"
import toast from "react-hot-toast"
import { Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from "@mui/material";
import AddUserForm from "../components/AddUserForm";
import { signIn } from "@api/user";

function TeacherPage(){
    const [open,setOpen]=useState(false)
    const [teachers,setTeachers]=useState([])
    const [selected_teacher,setSelectedTeacher]=useState(null)
    const [hidePassword,setHidePassword]=useState([])
    const loadTeachers=async()=>{
      const data=await getTeachersList();
      setTeachers(data);
      setHidePassword(new Array(data.length).fill(false))
  }
  useEffect(()=>{
    loadTeachers();
  
  },[])
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  async function copyTeacher(index){
  
    try{
    let teacher=teachers[index]
    let email=teacher.email
    let password=teacher.un_hashed_password
    let text = 
  `${email}
  ${password}`
     await navigator.clipboard.writeText(text);
     toast.success("Teacher copied successfully")
    }catch(err){
        console.log(err)
        toast.error("Error happened")
    }
   }
  
  return <>
  
  <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Change activity confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to change the activity of this teacher?
            
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button  color="success" variant="contained" onClick={async()=>{
              if(selected_teacher){
                await updateTeacherActivity(selected_teacher.id,selected_teacher.is_active==1?0:1)
               await loadTeachers();
              }
              handleClose()
           
            }
            }>Yes</Button>
            <Button color="error" variant="contained" onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <table style={{width:"100%"}}>
          <tr>
              <th>Teacher name</th>
              <th> Teacher password</th>
              <th >Teacher is active</th>
              <th>Copy teacher</th>
              <th>Log In</th>
          </tr>
      {teachers && teachers.map((teacher,index)=>{
          return <tr>
              <td>{teacher.email}</td> 
              {hidePassword[index]===false && <td > ************* <button onClick={()=>{
                setHidePassword((old)=>{
                  let new_hide=[...old]
                  new_hide[index]=true
                  return new_hide
                })
                setTimeout(()=>{
                  setHidePassword((old)=>{
                    let new_hide=[...old]
                    new_hide[index]=false
                    return new_hide
                  })
                },1500);
              }}>Show</button> </td>}
              {hidePassword[index] && <td > {teacher.un_hashed_password}   </td>}
              <td className="teacher-activity" onClick={async()=>{
               
                 setSelectedTeacher(teacher)
                 setOpen(true)
              }}>{teacher.is_active}</td>
              <td>
              <button onClick={()=>{
                   copyTeacher(index)
                  }}>Copy</button> 
              </td>
              <td>
                <button onClick={async()=>{
                   try{
                  await signIn(teacher.email,teacher.un_hashed_password);
                   }catch(err){
  
                   }
                }}>
                  Log In
                  </button>
              </td>
          </tr>
      })}
      </table>
         < AddUserForm loadTeachers={loadTeachers}/> 
      
  
  
  </>
  }

  export default TeacherPage