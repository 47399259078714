export const icons=[
    {
     id:1,
     icon:"🔔"
    },
    {
     id:2,
     icon:"🎉"
    },
    {
     id:3,
     icon:"⚠️"
    },
    {
    id:4,
    icon:"🚫"
    }
]