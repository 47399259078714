import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"



export const addToFavorite=async(quiz_id)=>{
    try{
      await axios.post(serverURL+"/favorites",{quiz_id},{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
      })
    }catch(err){
      console.log(err);
    }
}

export const removeFromFavorite=async(quiz_id)=>{
    try{
      await axios.delete(serverURL+"/favorites",{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
         },
         data:{
            quiz_id
         }
      })
    }catch(err){
      console.log(err);
    }
}

export const getFavorites=async(quiz_id)=>{
    try{
    const {data }= await axios.get(serverURL+"/favorites",{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
      })
      return data
    }catch(err){
     console.log(err);
    }
}

export const getFavorite=async(quiz_id)=>{
  try{
  const {data }= await axios.get(serverURL+"/favorites/"+quiz_id,{
      headers:{
          Authorization:window.localStorage.getItem("usertoken")
       }
    })
    return data
  }catch(err){
   console.log(err);
  }
}