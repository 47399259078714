import { useState ,useEffect } from "react"
import { TextField ,Paper, Button} from "@mui/material"
import { get_teacher_account,update_teacher_account } from "../API/teacher_account"

export default function TeacherProfileSettings(){
    const [hasChanged,setHasChanged]=useState(false)
    const [state,setState]=useState({
        publicName:"",
        publicPhoto:"",
        publicBio:"",
        totalViews:0,
        totalLikes:0,
        totalQuizes:0,
        teacher_link:""
      
    })
    const load_teacher=async()=>{
      try{
        console.log(await get_teacher_account())
        const {public_name,bio,image_url,views_count,likes_count,teacher_link} = await get_teacher_account();
        setState({
            ...state,
            publicName:public_name,
            publicBio:bio,
            publicPhoto:image_url,
            totalViews:views_count,
            totalLikes:likes_count,
            teacher_link
        })
      }catch(err){

      }
    }
    
    const update_teacher=async()=>{
        const {publicBio,publicName,publicPhoto}=state
        await update_teacher_account({
            public_name:publicName,
            bio:publicBio,
     image_url : publicPhoto 
        })
        setHasChanged(false)
    }
    const ignoreChanges=()=>{
        load_teacher();
        setHasChanged(false)
    }
    useEffect(()=>{
        load_teacher();
    },[])
    return <Paper elevation={3} sx={{p:3,overflowY:"scroll",m:3,height:"80vh" ,display:"flex",alignItems:"center",flexDirection:"column",gap:5}}>
     <div style={{width:"100%"}}>
     <div style={{width:"100%",textAlign:"right",direction:"rtl"}}>الاسم</div>
     <TextField value={state.publicName} onChange={(e)=>{
        setState({...state,publicName:e.target.value})
        setHasChanged(true)
     }} placeholder="الاسم" style={{width:"100%",textAlign:"right",direction:"rtl"}}/>
    </div>
    <div style={{width:"100%"}}>
     <div style={{width:"100%",textAlign:"right",direction:"rtl"}}>الصورة الشخصية</div>
     <TextField value={state.publicPhoto} onChange={(e)=>{
        setState({...state,publicPhoto:e.target.value})
        setHasChanged(true)
     }}   placeholder="الصورة الشخصية" style={{width:"100%",textAlign:"right",direction:"rtl"}}/>

     {state.publicPhoto && <img src={state.publicPhoto} style={{width:60,height:60,borderRadius:60}} />}
    </div>
    <div style={{width:"100%"}}>
     <div style={{width:"100%",textAlign:"right",direction:"rtl"}}>الوصف الشخصي</div>
     <TextField value={state.publicBio} onChange={(e)=>{
        setState({...state,publicBio:e.target.value})
        setHasChanged(true)
     }} multiline placeholder="الوصف الشخصي" style={{width:"100%",textAlign:"right",direction:"rtl"}}/>
    </div>
    <div style={{display:"flex",gap:10,alignItems:"center",justifyContent:"center"}}>
       {hasChanged&& <Button color="secondary" variant="contained" onClick={update_teacher}>حفظ التعديلات</Button>}
       {hasChanged&& <Button color="warning" variant="contained" onClick={ignoreChanges}>تجاهل التعديلات</Button>}
       
    
    </div>
    
    <div>
        <h2>إجمالي المشاهدات</h2>
        <div>{state.totalViews}</div>
    </div>
    <div>
       <h2>إجمالي الإعجابات</h2>
        <div>{state.totalLikes}</div>
    </div>
    <a href={"/teacher/"+state.teacher_link} ><Button variant="contained">صفحتي الشخصية</Button></a>
   
    </Paper> 
}