import { Button } from "@mui/material"
import { saveProgress,loadProgress } from "../../../API/progressSave";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from "react";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function Navbar({setQuiz}){

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    const saveToServer=async()=>{
        try{
          await saveProgress(localStorage.getItem("questions"));
    
        }catch(err){
    
        }
      }
      const loadFromServer=async()=>{
        try{
         const data= await loadProgress();
       setQuiz(JSON.parse(data.progress));
        
        }catch(err){
    
        }
      }


    return <>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{textAlign:"right",direction:"rtl"}} id="modal-modal-title" variant="h6" component="h2">
            استرجاع المحفوظ
          </Typography>
          <Typography  sx={{textAlign:"right",direction:"rtl"}} id="modal-modal-description" sx={{ mt: 2 }}>
            استرجاع المعلومات المحفوظة على السرفير ستمحي أي تقدم غير محفوظ
          </Typography>
          <div style={{margin:5,display:"flex",gap:10,alignItems:"center",justifyContent:"space-around"}}>
         <Button onClick={handleClose} variant="contained" color="error" >غلق النافذة</Button>
      
             <Button onClick={loadFromServer} variant="contained" color="success"> استرجاع المحفوظ</Button>
           
          </div>
           </Box>
      </Modal>
    <Button 
    sx={{m:1}}
   variant="contained"
   onClick={handleOpen}>استرجاع المحفوظ</Button>
   <Button
   sx={{m:1}}
   variant="contained" onClick={saveToServer}>حفظ للسيرفر</Button>
 
    </>
}

export default Navbar