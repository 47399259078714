import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL 

export const addQuiz=async(questions,tags,title,description,imageThumbnail,grades,material,level)=>{

   
 try{
  
     let {data} = await axios.post(serverURL+"/quiz",{
        questions,tags,title,description,imageThumbnail,grades,material,level
     },{
      headers:{
         Authorization:window.localStorage.getItem("usertoken")
      }
     })
     
     return data.id
 }catch(err){
   console.log(err)
    
 }

}

export const getQuiz=async(id,type,question)=>{
   const randomArr=(arr)=>{
      for(let i=0;i<20;i++){
         let index1=Math.floor(Math.random()*arr.length)
         let index2=Math.floor(Math.random()*arr.length)
         let temp=arr[index1]
         arr[index1]=arr[index2]
         arr[index2]=temp
      }
      return arr
   }
   try{
     let {data}=await axios.get(serverURL+"/quiz/"+id+"?type="+type)
     data=data.map(item=>{
      let options=[]
      for(let key in item){
         if(key.includes("option") && item[key]){
            options.push(item[key])
         }
       
      }
     
      item.options=randomArr(options.map((item,index)=>{
         return {data:item,index}
      }));
      return item
     })
     return data
  }catch(err){
    console.log(err)
     //throw new Error(err.message)
  }
}

export const search=async(term)=>{
  
   try{
     
     const {data}=await axios.get(serverURL+"/quiz/search/term/"+term,
      {
         headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
        }
     )
     
     return data
   }catch(err){
       console.log(err)
   }
}

export const getResults=async(list)=>{
   try{
     const {data} = await axios.post(serverURL+"/quiz/searchresults",{
      list
     },{
      headers:{
         Authorization:window.localStorage.getItem("usertoken")
      }
     })
     return data
   }catch(err){

   }
}

export const loadReleventQuizes=async(shift)=>{
   try{
      
     const {data} = await axios(serverURL+"/quiz/relevant?shift="+shift,{
      method:"GET",
      withCredentials:true,
      crossDomain: true,
      
      headers:{
         Authorization:window.localStorage.getItem("usertoken")
      },
     /* withCredentials:true,
      credentials: 'include'*/
   });
  
     return data;
   }catch(err){

   }
}

export const getTeacherQuizes=async()=>{
   try{
     const {data}=await axios.get(serverURL+"/quiz/teacherquizes",{
      headers:{
         Authorization:window.localStorage.getItem("usertoken")
      }
     })
     
     return data
   }catch(err){
      console.log(err)

   }
}



export const generateQuizReport=async(id)=>{
   try{
     const {data}=await axios.get(serverURL+"/quiz/quizreport/"+id)
  
     return data
   }catch(err){

   }
}

export const like=async(id)=>{
   try{
      const {data}=await axios.post(serverURL+"/quiz/like/"+id,{},{
         headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
      })
      return data
   }catch(err){
  alert(err)
   }
}
export const dislike=async(id)=>{
   try{
      const {data}=await axios.post(serverURL+"/quiz/dislike/"+id,{},{
         headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
      })
     return data
   }catch(err){

   }
}

export const loadLikes=async(id)=>{
   try{
      const {data}=await axios.get(serverURL+"/quiz/likes/"+id,{
         headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
      })
     return data
   }catch(err){

   }
}

export const loadAllQuizes=async()=>{
   try{
      const {data}=await axios.get(serverURL+"/quiz/all")
      return data
   }catch(err){
   
   }
}

export const  toggleQuiz=async(id)=>{
   try{
      await axios.put(serverURL+"/quiz/toggle/"+id)
      
   }catch(err){
   
   }
}

export const deleteQuiz=async(id)=>{
   
   try{
     await axios.delete(serverURL+"/quiz/"+id)
      
   }catch(err){
   
   }
}