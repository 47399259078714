import './App.css';
import { Route,Routes } from 'react-router-dom';
import Home from './pages/Home';
import Quiz from './pages/Quiz';
import GenerateQuiz from './pages/GenerateQuiz';
import GenerateQuiz2 from './pages/GenerateQuiz2';
import Header from './components/Header';
import TeacherProfileSettings from './pages/TeacherProfileSettings';
import Dashboard from './pages/Dashboard';
import Admin from "./pages/AdminPage"
import {useEffect} from 'react';
import AuthComponent from './components/Auth';
import useUserAuth from './hooks/useUserAuth';
import { updateToken } from './util/token';
import { getTokenData } from './API/user';
import ContactUs from './pages/ContactUs';
import {Toaster} from "react-hot-toast"
import ReportPage from './pages/ReportPage';
import QuizReport from './pages/QuizReport';
import TeacherAgreement from './pages/Agreements/TeacherAgreement';
import TeacherSigning from './pages/Agreements/TeacherSigning';
import PrivacyPolicy from './pages/Agreements/PrivacyPolicy';
import UsageAgreement from './pages/Agreements/UsageAgreement';
import Footer from './components/Footer';
import ActivateAccountStudent from './pages/ActivateAccountStudent';
import ResetPassword from './pages/ResetPassword';
import TrainingCenter from './pages/TrainingCenter';
import SearchActivities from './pages/SearchActivities';
import FavoritesPage from './pages/FavoritesPage';
import TeacherNotification from './components/TeacherNotification';
import TeacherNotificationPage from './pages/TeacherNotificationPage';
import TeacherPage from './pages/TeacherPage';
//import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import GifGenerator from './components/GifGenerator';
import { useRef } from 'react';
import axios from 'axios';

function App() {
  const tawkMessengerRef = useRef();


  const onLoad=()=>{
    tawkMessengerRef.current.visitor({
      name : 'name',
      email : 'email@email.com'
  });
  }
  const [user,setUser]=useUserAuth({
    logged:false,
    type:""
  })
  const loadTokenData=async()=>{
     let token=window.localStorage.getItem("usertoken")
     if(token && token !="undefined"){

     let tokenData=await getTokenData(token)

      updateToken(tokenData)
     }
  }
  useEffect(()=>{
    if(window.localStorage.getItem("usertoken")){
     loadTokenData();
    }

  },[])




  function WrapAuth({Element}){
      if(!user.logged){
        return <h1>Require auth</h1>
      }

  
     
      if(user.type=="teacher" && user.active==0){
        
        return <TeacherSigning user={user} setUser={setUser} />
      }
      

      return <Element user={user}/>
  }
 // return <PrivacyPolicy/>
  return (
    <div className="App" style={{display:"flex",flexDirection:"column",minHeight:"100vh"}}>
      {/*<GifGenerator/>*/}
      <Toaster/>
        {user && user.logged && user.type=="teacher"  && <button onClick={()=>{
          localStorage.setItem("questions",JSON.stringify(
            {
              title:"",
              description:"",
              imageThumbnail:"",
              questions:new Array(10).fill(0).map(item=>{
                return {
                  statment:"",
                  options:["","","","",""],
                  correct:"0",
                  imageURI:"",
                  reasoning:""
                }
              }),
              tags:[],
              grades:[],
              level:"",
              countries:[],
              material:"0"
            }
          ))
        }}>

          اضغط الزر لحل مشكلة الشاشة البيضاء
          </button>}
        {/*<TawkMessengerReact
       
         propertyId="667af6e6eaf3bd8d4d144a51"
          widgetId="1i1858srm"
          onLoad={onLoad}
          ref={tawkMessengerRef}
        /> */}
      

     <Header user={user}/>
    {user&& user.logged&& user.type=="student"&&user.active==0&&(
      <ActivateAccountStudent user={user} setUser={setUser}/>
    )}
     {!(user&& user.logged&& user.type=="student"&&user.active==0)&&<main style={{flex:1 }}>
        <Routes >
        <Route   path="/" element={<Home user={user}  />} />
        <Route path="/quiz/:id" element={<Quiz user={user}/>} />
     
        <Route path="/contactus" element={<ContactUs/>} />
        <Route path="/report" element={<ReportPage/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route path="/usageagreement" element={<UsageAgreement/>}/>
        <Route path="/resetpassword" element={<ResetPassword/>}/>
        <Route path="/teacher/:id" element={<TeacherPage/>} />
        {!user || !user.logged && (
          <>
           <Route path="/login" element={<AuthComponent/>} />
         
           </>
        )}
       
        {/*teacher routes*/}
        {user && user.type=="teacher" &&(
          <>
        <Route path="/generate" element={<WrapAuth Element={GenerateQuiz}/>} />
        <Route path="/generate2" element={<WrapAuth Element={GenerateQuiz2}/>} />
        <Route path="/dashboard" element={<WrapAuth  Element={Dashboard}/>} />
        <Route path="/quizreport/:id" element={<WrapAuth Element={QuizReport}/>} />
        <Route path="/teacheragreement" element={<TeacherAgreement/>} />
        <Route path="/teachertraining" element={<TrainingCenter/>} />
        <Route path="/searchactivities" element={<SearchActivities/>} />
        <Route path="/notificationpage" element={<TeacherNotificationPage/>} />
        <Route path="/teacherpagesetting" element={<WrapAuth Element={TeacherProfileSettings}/>} />
        </>
        )}

        {user && user.logged &&<>
          <Route path="/favorites" element={<FavoritesPage user={user}/>} />
        </>}
        
        {/*admin route*/}
        <Route path="/admin" element={<Admin/>} />
      </Routes>
      </main>}
      {user && user.logged && user.type==="teacher" && <TeacherNotification/>}
      <Footer user={user}/>

     
    
    </div>
  );
}

export default App;
