import DeleteForever from "@mui/icons-material/DeleteForever"

function TagComp({tag,setQuiz,quiz}){
    return <div style={{padding:"5px",  backgroundColor:"lightgreen",borderRadius:"10px",display:"flex",alignItems:"center",justifyContent:"center"}}>
      {tag} <DeleteForever className="delete-icon" onClick={()=>{

        let new_tags=quiz.tags.filter(item=>item !=tag)
        setQuiz({...quiz,tags:new_tags})
      }}/>
    </div>
  }
  
export default TagComp