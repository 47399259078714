import { useState, useEffect } from 'react';

const useUserAuth= (initial) => {
  const [storedValue, setStoredValue] = useState(initial);


  useEffect(() => {
    const handleCustomStorageChange = (event) => {
      
        setStoredValue(event.detail.newValue);

    };

    window.addEventListener('localStorageChange', handleCustomStorageChange);

    return () => {
  
      window.removeEventListener('localStorageChange', handleCustomStorageChange);
    };
  }, []);

  const setStored=(new_value)=>{
      setStoredValue(new_value)
      if(new_value.token){
        window.localStorage.setItem("usertoken",new_value.token)
      }
  }

  return [storedValue,setStored];
};

export default useUserAuth;
