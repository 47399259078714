import { useState,useEffect } from "react"
import { Paper, Button} from '@mui/material';
import { loadBackUpDirectoryFiles,downloadDatabase,uploadDatabase,deleteFile,moveToProduction } from "@api/database";


function SaveDataBasePage(){
    const [file,setFile]=useState(null);
    const [files,setFiles]=useState([])
    const loadFiles=async()=>{
      try{
        const data=await loadBackUpDirectoryFiles();
        setFiles(data);
  
      }catch(err){
  
      }
    }
    useEffect(()=>{
    loadFiles();
    },[])
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
  };
    return <div>
      <Paper elevation={2} sx={{m:3,p:2}}>
        <div>تنزيل قاعدة البيانات المعمول بها حاليا</div>
          <Button onClick={()=>{
        downloadDatabase();
      }} variant="contained" color="success"> Download database copy</Button>
      </Paper>
    
  
      <Paper elevation={2} sx={{m:3,p:2}}>
        <div>رفع قاعدة البيانات الى ملف التخزين المؤقت backups</div>
        <input type="file" onChange={handleFileChange} />
        <button onClick={async()=>{
          await uploadDatabase(file)
          await loadFiles();
          }}>Upload Database</button>
      </Paper>
  
      <Paper elevation={2} sx={{m:3,p:2}}>
        <div>الملفات المؤقتة</div>
        <table style={{width:"100%"}}>
          <tr>
            <th>اسم الملف</th>
            <th>تاريخ الاضافة</th>
            <th>حجم الملف</th>
            <th>حذف الملف</th>
            <th>استخدام في الproduction</th>
          </tr>
    
        {files && files.map && files.map(item=>{
          return <tr>
            <td>{item.name}</td>
            <td>{item.creationDate}</td>
            <td>{item.sizeMB} MB</td>
            <td><button onClick={async()=>{
              try{
                await deleteFile(item.name)
                await loadFiles();
  
              } catch(err){
                console.log(err)
              }
            }}>حذف</button></td>
            <td><button onClick={async()=>{
               await moveToProduction(item.name);
            }}>استخدام</button></td>
          </tr>
        
        })}  </table>
      </Paper>
     
    </div>
  }

  export default SaveDataBasePage