import { Box,Button } from "@mui/material";
import SelectComp from "../components/SelectComp";
import { useState } from "react"
import DeleteForever from "@mui/icons-material/DeleteForever";

const levelOptions=[
        {
            label:"سهل",value:"0"
        },
        {
            label:"متوسط",value:"1"
        },
        {
            label:"صعب",value:"2"
        }
]
const map={
    "0":"أطفال",
    "1":"أول ابتدائي",
    "2":"ثاني ابتدائي",
    "3":"ثالث ابتدائي",
    "4":"رابع ابتدائي",
    "5":"خامس ابتدائي",
    "6":"سادس ابتدائي",
    "7":"سابع اعدادي",
    "8":"ثامن اعدادي",
    "9":"تاسع اعدادي",
    "10":"عاشر ثانوي",
    "11":"حادي عشر ثانوي",
    "12":"بكالوريا ثانوي",
    "13":"جامعي",
    "14":"ثقافة عامة"
}
const countriesMap={
    "0":"سوريا",
    "1":"فلسطين",
    "2":"الأردن",
    "3":"لبنان",
    "4":"مصر",
    "5":"ليبيا",
    "6":"الجزائر",
    "7":"العراق",
    "8":"الكويت",
    "9":"قطر",
    "10":"البحرين",
    "11":"السعودية",
    "12":"اليمن",
    "13":"الإمارات",
    "14":"عمان",
    "15":"السودان",
    "16":"",
    "17":"",
}

const materialsMap={
    "0":"لغة أجنبية",
    "1":"اللغة العربية",
    "2":"رياضيات",
    "3":"فيزياء",
    "4":"كيمياء",
    "5":"بيولوجيا",
    "6":"تاريخ",
    "7":"جغرافيا",
    "8":"تربية دينية",
    "9":"علوم الحاسب",
    "10":"الفلسفة",
    "11":"ثقافة عامة",
    "12":"مستوى جامعي",
}

const materialOptions=new Array(13).fill(0).map((_,index)=>{
    return {
        value:index,
        label:materialsMap[index]
    }
})

const gradeOptions=new Array(15).fill(0).map((item,index)=>{
    return {
        value:index,
        label:map[index]
    }
})




function RenderAudiencePage({quiz,setQuiz}){
   const [grade,setGrade]=useState("0")

    return <div
    style={{
        width:"100%",
        height:"60vh",
        display:"flex",
        alignItems:"flex-start",
        justifyContent:"space-around"
    }}
    >
   <Box sx={{border:"2px dashed black",p:4,height:"100%",width:"100%",   display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>
     <Box>
       <h1>مستوى الصعوبة</h1>
       <SelectComp 
       label={"الصعوبة"}
       value={quiz.level}
       handleChange={(e)=>{
        setQuiz({...quiz,level:e.target.value})
       }}
       options={levelOptions}
       />
     </Box>
     <Box>
         <h1>المادة الدراسية</h1>
        <SelectComp

        value={quiz.material}
        handleChange={(e)=>{
            setQuiz({...quiz,material:e.target.value})

        }}
        options={materialOptions}
        label={"المادة الدراسية"}


      />
     </Box>
    </Box>

   <Box sx={{border:"2px dashed black",width:"100%",height:"100%",p:10,overflow:"scroll"}}>
        <h1>المرحلة الدراسية /الجمهور المستهدف </h1>
        <SelectComp 
       label={"المرحلة الدراسية"}
       value={grade}
       handleChange={(e)=>{
       setGrade(e.target.value)
       }}
       options={gradeOptions}
       />
       <Button onClick={()=>{
        let grades=[...quiz.grades]
        console.log(grades.find((item)=>item==grade))
        if(grades.find((item)=>item==grade)) return;
        setQuiz({...quiz,grades:[...grades,grade]})
       }} variant="contained" sx={{m:5}}>إضافة</Button>
        <div>
            {quiz.grades && quiz.grades.map(item=>{
                return <div style={{background:"lightgreen",padding:5,margin:5,borderRadius:10}}> <span onClick={()=>{
                    let grades=quiz.grades.filter(el=>el !=item);
                    setQuiz({...quiz,grades})
                }}><DeleteForever className="delete-icon"/> </span> {map[item]}</div>
            })}
        </div>
     </Box>

  
        
    </div>
}

export default RenderAudiencePage