import { Link, useParams } from "react-router-dom"
import { useState,useEffect } from "react"
import { Button ,Box,Stack} from "@mui/material"
import { getQuiz } from "../API/quiz"
import ShareSharp from "@mui/icons-material/ShareSharp"
import ShareModal from "../components/ShareModal"
import { submitAnswer } from "../API/submit"
import toast from "react-hot-toast"
import { generateSessionId } from "../util/functions"
import { useNavigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import { sentCountSource } from "../API/view"

function Question({setQuestions,reasoning,setReasoning,order,session_id,question,id,submited,setAnswer,setCorrect,setSubmitted,correct,answer}){
    const [open, setOpen] = useState(false);
    const [params]=useSearchParams();
    
    let s=params.get("s")
   

      const selectOption=async(option)=>{
        if(submited) return;
        try{
         const result = await submitAnswer(id,question.id,session_id,option,order,localStorage.getItem("user_type"))  
         console.log(result);
         setCorrect(result.correct);
         setAnswer(result.answer);
         setReasoning(result.reasoning);

         setQuestions((questions)=>{
          let new_questions=[...questions]
          new_questions[order]["correct"]=result.correct
          new_questions[order]["answer"]=result.answer
          return new_questions
          
         })
         if(result.result){
          toast.success("إجابة صحيحة 👏")
         }else{
          toast.error("إجابة خاطئة ✖️")
         }
         setSubmitted(true);
        }catch(err){
         console.log(err)
        }
      }
     const countSource=async()=>{
      if(s !=="repeat"){
         await sentCountSource(id,s);
      }
     }
      useEffect(()=>{
       countSource();
      },[])

       if(question){
    return <>

   
    <ShareModal quiz_id={id} {...{open,setOpen}}/>
    
    <Box className="question-box" sx={{m:1,p:1,border:"1.5px solid #00A2E8"}}>
        <Box sx={{p:1,fontSize:25,display:"flex",alignItems:"center",justifyContent:"center",gap:1}}>
          <Button onClick={()=>setOpen(true)} startIcon={ <ShareSharp/>}>
         مشاركة الإختبار 
         </Button>
            </Box>
    { question.imageURI && <img style={{width:"700px",maxWidth:"90%"}} src={question.imageURI} />}
    <Box sx={{border:"1px dashed black",p:1,fontSize:25}}>{  question.statment}</Box>
    <Stack spacing={1} sx={{m:1}}>
    {question.options&&question.options.map((item,inx)=>{
        return <Button 

        onClick={()=>{selectOption(item)}}
        sx={{fontFamily:"cocon-next-arabic",fontSize:25}}
        
        color={submited?(item.index==correct?"success":(item.index==answer?"error":"primary")):"primary"} 
        
        variant={"contained"}
        
        
        >{item.data}</Button>
    })}
    </Stack>
    {reasoning && reasoning !="" && <Box sx={{border:"1px dashed black",padding:1}}>
    💡{reasoning}💡
      </Box>}

</Box></>
       }
       return <h1>Done with quize</h1>
  
}
export default function Quiz({user}){
  const [session_id,setSession_id]=useState(generateSessionId())
    const {id} = useParams()
    const [reasoning,setReasoning]=useState("")
    const [question,setQuestion]=useState(0)
    const [questions,setQuestions]=useState([])
    
    const [submited,setSubmitted]=useState(false);
    const [correct,setCorrect]=useState(0)
    const [answer,setAnswer]=useState(0)
    
    const navigator=useNavigate();
    const submitQuiz=()=>{

     if(!user||!user.logged){
      
      navigator("/login?id="+id+"&session="+session_id+"&state=1",
        {state:{questions}}
      );
    
    }else{

      navigator("/report?id="+id+"&session="+session_id,
      { state: { questions }}
      );
    }
    }

    useEffect(()=>{
     loadQuiz()
    },[])
    const loadQuiz=async()=>{
     try{
      let data=await getQuiz(id,localStorage.getItem("user_type")=="teacher"?"teacher":"other")
     
     
      setQuestions(data)
   
     }catch(err){
         console.log(err)
     }
    }
    if(questions && questions[question]){
    return <>
    
       <Question 
       reasoning={reasoning}
       setReasoning={setReasoning}
       setQuestions={setQuestions} session_id={session_id} answer={answer} setAnswer={setAnswer}
       correct={correct} setCorrect={setCorrect}
       setSubmitted={setSubmitted}
       submited={submited}
       order={question}
       id={id} question={questions[question]}  />
           
        <div style={{display:"flex",gap:"15px",alignItems:"center",justifyContent:"center"}}>
        {question==questions.length-1&&submited && <Button variant="contained" color="success"
        onClick={submitQuiz}
        
        >
            {(!user ||!user.logged)&&<>تسجيل الدخول</>}
             {(user&&user.logged)&&<>إنهاء الإختبار</>}
            </Button>}
            {(!user||!user.logged)&&(question==questions.length-1&&submited )&&<p>يرجى تسجيل الدخول من أجل إنهاء الإختبار وعرض تقرير الإختبار</p>}
       
        {question<questions.length-1 &&submited &&<Button onClick={()=>{
          
            setQuestion(question+1)
            setAnswer(0)
            setCorrect(0)
            setSubmitted(false)
            setReasoning("")
        }} variant="contained">السؤال التالي</Button>}
        
       </div>
    </>
    }else{
        return <h1>الإختبار غير متوفر في الوقت الحالي</h1>
    }
}