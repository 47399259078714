import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSearchParams } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsComp({tabs}) {
 
  let [searchParams, setSearchParams] = useSearchParams();
 let value=searchParams.get("tab")
 if(!value){
  value=0;
 }
 value=Number(value);

 console.log("value ",value)
  const handleChange = (event, newValue) => {
    setSearchParams((old)=>{
      old.set("tab",newValue)
      return old
    })
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
         
          {tabs.map((item,index)=>{
            return  <Tab label={item.title} {...a11yProps(index)} />
          })}
        </Tabs>
      </Box>
     
      {tabs.map((item,index)=>{
         return <CustomTabPanel value={value} index={index}>
         {item.comp}
       </CustomTabPanel>
      })}
    </Box>
  );
}
