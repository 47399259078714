import React, { useState } from 'react';
import { Button,TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { signUp } from '../API/user';
import {Link} from "react-router-dom";
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: 1,
      width: '25ch',
    },
  },
}));

const USERNAME="اسم المستخدم"
const EMAIL="البريد الإلكتروني"
const PASSWORD="كلمة المرور"
const SIGNUP="إنشاء الحساب"

const validateEmail = (email) => {
  const commonProviders = [
    'gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com', 'aol.com', 
    'icloud.com', 'mail.com', 'zoho.com', 'protonmail.com', 'yandex.com',
    'live.com', 'me.com', 'msn.com', 'comcast.net', 'att.net',
    'verizon.net', 'cox.net', 'charter.net', 'sbcglobal.net', 'bellsouth.net'
  ];
  const emailRegex = new RegExp(
    `^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@(${commonProviders.join('|')})$`,
    'i'
  );

  return emailRegex.test(String(email).toLowerCase());
};
const SignUpForm = ({redirect}) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [username,setUsername] = useState('')
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error,setError]=useState("")
  const [captchaValue, setCaptchaValue] = useState(null);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
};
  const handleSignUp = async() => {
  
    try{
      if(email==""){
        setError("يجب إدخال بريد إلكتروني")
        return;
      }
      
      if(!validateEmail(email)){
        setError("مزود البريد الإلكتروني غير معتمد من قبل المنصة يرجى إستخدام الإيميل الشخصي")
        return
      }
      if(username=="" || username.length<5 || username>50){
        setError("إسم المستخدم يجب أن يكون بين خمسة وخمسين حرف")
        return;
      }
      if(password.length<8 || password.length>50){
        setError("كلمة السر يجب أن تكون بين ثمانية وخمسين حرف")
        return
      }
      
      if(password !=confirmPassword){
        setError("يجب أن يكون توكيد كلمة المرور مطابق لكلمة المرور")
        return
      }
      if(await signUp(password,email,username,captchaValue)){
       redirect()
      }
      
    }catch(err){
      toast.error(err.message)
    }
  };

  return (
    
    <form style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} className={classes.form}>
      <TextField
        label={EMAIL}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <TextField
        label={USERNAME}
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <TextField
        label={PASSWORD}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <TextField
        label={"تأكيد كلمة المرور"}
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      {error !="" && <div style={{color:"red"}}>{error}</div>}
      
      <ReCAPTCHA
                sitekey={process.env.REACT_APP_reCAPTCHA_CLIENT_KEY}
                onChange={handleCaptchaChange}
            />
      <div style={{width:"fit-content", display:"flex",gap:5,alignItems:"center",justifyContent:"center"}}>
        <div> <Link to="/privacypolicy">بيان الخصوصية</Link> و <Link to="/usageagreement">اتفاقية الاستخدام</Link></div>
        <div>بإنشاء الحساب أنت توافق ضمنا على</div>
      
      </div>

      <Button variant="contained" color="primary" onClick={handleSignUp}>
        {SIGNUP}
      </Button>
    </form>
  );
};

export default SignUpForm;