import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL 

export const sendMessage=async(data)=>{
    
    try{
      await axios.post(serverURL+"/contactus",data)
    }catch(err){

    }
}