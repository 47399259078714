import { useState } from "react";
import { addTeacher } from "@api/admin";
import { TextField,Paper, Button, Typography, Grid } from '@mui/material';


const AddUserForm = ({loadTeachers}) => {
    const [userData, setUserData] = useState({
      username: '',
      password: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUserData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      // Here you can submit the form data to your backend or perform any other actions

      // Reset the form after submission
      await addTeacher(userData.username,userData.password)
      loadTeachers();
      setUserData({
        username: '',
        password: ''
      });
    };
  
    return (
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5" gutterBottom>Add Teacher</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={userData.username}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                value={userData.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Add Teacher
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  };


  export default AddUserForm
