import { Link } from "react-router-dom"
const Footer=({user})=>{
    
 return <footer style={{display:"flex",justifyContent:"space-between",alignItems:"start", padding:15,background:"rgb(50,50,50)",width:"100%",color:"white",minHeight:"100px", height:"fit-content",textAlign:"center"}}>
  <div style={{textAlign:"left"}}>
    <p style={{margin:0}}>United states of america</p>
    <p style={{margin:0}}>MI , 48126</p>
    <p style={{margin:0}}>ekhtibar@gmail.com</p>
    <p style={{margin:0}}>+1 (313) 677-5744</p>
    
  </div>
  
  <div style={{textAlign:"right"}}>
  <div>
   &copy; جميع الحقوق محفوظة لمنصة اختبار   
  </div>
  <div  style={{ display:"flex",flexDirection:"row-reverse",gap:10,justifyContent:"space-around",alignItems:"center",margin:"auto",width:"fit-content"}}>

  <Link className="footer-link" to="/usageagreement">اتفاقية الاستخدام</Link>
  <Link className="footer-link" to="/privacypolicy">بيان الخصوصية</Link>
  {user&&(user.type=="teacher")&&<Link className="footer-link" to="/teacheragreement">اتفاقية صانع المحتوى</Link>}
</div>
</div>
 </footer>
}

export default Footer