import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL 

 let map={
    "null":0,
    "student":1,
 }
 const platforms=["fb","yt","x",
    "wa","tg","in","li","s1","s2","s3","s4"
  ]

  for(let i=0;i<platforms.length;i++){
    map[platforms[i]]=i+2
  }

export const sentCountSource=async(quiz_id,s)=>{

  
    try{
         await axios.get(serverURL+"/view?s="+map[s]+"&quiz_id="+quiz_id);

    }catch(err){

    }
}

export const getSourceCount=async(quiz_id)=>{
    try{
     let {data}=await axios.get(serverURL+"/view/"+quiz_id)
     data=data.map(item=>map[item])
     
     return data
    }catch(err){

    }
}

