import { TextField,Button,Paper} from "@mui/material"
import TagComp from "../components/TagCom";
import toast from "react-hot-toast";
function RenderTagsPage({quiz,tag,setTag,setQuiz}){
    return <div style={{
      width:"100%",
      height:"80vh",
      display:"flex",
      alignItems:"flex-start",
      justifyContent:"space-around"
      }}>
        
        <div style={{width:"100%",height:"100%"}}>
          <Paper elevation={3} style={{width:"100%",height:"100%",padding:10,margin:10,backgroundColor:"rgb(220,230,255)"}}>
            <h1>إضافة الوسوم</h1>
           <div style={{display:"flex",alignItems:"center",justifyContent:"start",flexDirection:"column",gap:5}}>
            <TextField label="الوسم"
            style={{textAlign:"right",direction:"rtl"}}
  
            value={tag} onChange={(e)=>{
              setTag(e.target.value);
            }} />
            <Button variant="contained" color="success" onClick={()=>{
              if(quiz.tags.includes(tag)) return;
              if(tag=="") return;
              if(quiz.tags.length==10){
                toast.error("لا يمكن إضافة أكثر من 10 وسوم")
                return;
              }
              setQuiz({...quiz,tags:[...quiz.tags,tag]})
              setTag("")
            }} >إضافة الوسم</Button>
            {quiz.tags.map(item=>{
              return <TagComp tag={item} quiz={quiz} setQuiz={setQuiz} />
            })}
            </div>
  
          </Paper>
  
             
         
    </div>
  
    </div>
  }
  
  
  export default RenderTagsPage