import { Paper,Stack,Button } from "@mui/material"
import { maxWidth } from "@mui/system";
import { useState } from "react";
import "./TrainingCenter.css"
import { useSearchParams } from "react-router-dom";

const lessons=[
{
  id:1,
  title:"إضافة إختبار",
  src:"https://www.youtube.com/embed/pAYiuvWMwgU",
  description:"كيفية إضافة إختبار إلى المنصة "
},
{
  id:2,
  title:"إضافة صورة",
  src:"https://www.youtube.com/embed/kFqvQv5iWlQ",
  description:"كيفية إضافة صورة للاختبارات"
},
/*{
  id:3,
  title:"مركز الإختبارات",
  src:"https://www.youtube.com/embed/2sx-qB4wpPI",
  description:"كيفية قراءة إحصاءات الإختبارات"

}*/

]
const TrainingCenter=()=>{

let [searchParams, setSearchParams] = useSearchParams();
let selectedLesson=searchParams.get("lesson")
if(!selectedLesson){
  selectedLesson=0
}

const currentLesson=lessons.find((item,index)=>index==selectedLesson);
 return <div><div className="div" style={{padding:"15px",gap:10,height:"100%",width:"100%",display:"flex",alignItems:"start",justifyContent:"center"}}>

   
      <Paper sx={{width:"100%"}} elevation={2}>
     
   
        <div class="container">
  <iframe class="responsive-iframe" src={currentLesson.src}></iframe>
</div>
       <div>
         {currentLesson.description}
       </div>
      </Paper>
 <Paper  className="stack" elevation={2}>
      <Stack direction={{xs:"row",md:"column"}} gap={2} sx={{p:2,flexWrap:"wrap"}}>
        {lessons&& lessons.map((lesson,index)=>{
            return <Button onClick={()=>{
            
                setSearchParams(old=>{
                  old.set("lesson",index)
                  return old
                })
            }} color={index==selectedLesson?"success":"info"} variant="contained" sx={{minWidth:"fit-content",paddingLeft:2,paddingRight:2,direction:"rtl",textAlign:"right"}}>{lesson.title}</Button>
        })}
      </Stack>
    </Paper>
 </div>
 

   <div>
    {/* Later add like button / dislike button / feedback option */}
   </div>
 </div>
}

export default TrainingCenter