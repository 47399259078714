
import { useState,useEffect } from "react"
import { getSearchActivities } from "../API/searchActivities"
const SearchActivities=()=>{
    const [arr,setArray]=useState([])
    const [filter,setFilter]=useState("")

    const loadSearchActivites=async()=>{

        try{
          let searches=await getSearchActivities();
         setArray(searches)

        }catch(err){

        }

    }
    useEffect(()=>{
     loadSearchActivites();
    },[])
 return <div style={{padding:15}}>

    <input type="text"   value={filter} placeholder="مصطلح"  onChange={(e)=>{
     setFilter(e.target.value)
    }}/>

    <table style={{width:"100%"}}>
        <tr>
            <th>مصطلح البحث</th>
            <th>تاريخ البحث</th>
             <th>عدد نتائج البحث</th>
        </tr>
          {arr&& arr.map(item=>{
            if(filter==""){
    return <tr>
        <td>{item.term}</td>
        <td>{item.created_at}</td>
        <td>{item.count}</td>
    </tr>
            }
        if(item.term.toLowerCase().includes(filter.toLowerCase())){
            return <tr>
            <td>{item.term}</td>0
            <td>{item.created_at}</td>
            <td>{item.count}</td>
        </tr>

        }
    

})}
    </table>

 </div>
}
export default SearchActivities