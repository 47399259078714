import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import { like,dislike } from '../API/quiz';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { addToFavorite,removeFromFavorite } from '../API/favorite';
import toast from "react-hot-toast"
import { toggleQuiz } from '../API/quiz';
import { isValidImg } from '../util/images';
import NoImage from "../images/NoImage.jpg"
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardComp({user,quiz,setQuiz,setOpen,setSQuiz,distanation}) {


  const navigator=useNavigate()
 
console.log(quiz);
  return (
    <Card  className='quiz-thumbnail-container'>

      <CardHeader
        className="quiz-thumbnail"
        onClick={()=>{
          navigator("/"+distanation+"/"+quiz.id)
       }} sx={{ width:"100%" }}
        title={quiz?.title?.trim()===""?"عنوان الإختبار":quiz.title}
        subheader={quiz.created_at}
      >
        
      </CardHeader>
    
      <CardContent 
      className="quiz-thumbnail"
      
      onClick={()=>{
        navigator("/"+distanation+"/"+quiz.id)
    }} sx={{ width:"100%"}}>
      {isValidImg(quiz.imageThumbnail) && <img style={{borderRadius:10}} width={"100%"} src={quiz.imageThumbnail}/>}
      {!isValidImg(quiz.imageThumbnail) && <img style={{borderRadius:10}} width={"100%"} src={NoImage}/>}
      
        <Typography variant="body2" color="text.secondary">
         {quiz?.description?.trim()===""?"وصف الإختبار":quiz.description}
        </Typography>
      </CardContent>



      <CardActions >
       
        <IconButton onClick={()=>{
          setSQuiz(quiz.id)
          setOpen(true)
        }
        }  aria-label="share">
          <ShareIcon />
        </IconButton>
       <div style={{display:'flex',gap:10,alignItems:"center"}}>
        < VisibilityIcon/>
        {quiz.views?quiz.views:0}
       </div>

       <div onClick={async()=>{
        if(!user || !user.logged) return
       const data=await like(quiz.id)
       setQuiz((old)=>{
        let newArr=[...old]
        let item= newArr.find(item=>item.id==quiz.id)
        item.likes_count=data.likes_count
        item.dislikes_count=data.dislikes_count
        item.liked=data.liked
        item.disliked=data.disliked
        return newArr
       })
      
       }} className="like-icon" style={{display:'flex',gap:10,alignItems:"center"}}>
        < ThumbUpIcon style={{color:quiz.liked?"rgb(20,150,200)":"rgb(200,200,200)"}}/>
        {quiz.likes_count?quiz.likes_count:0}
       </div>
       <div onClick={async()=>{
  if(!user || !user.logged) return
       const data =await dislike(quiz.id)
       setQuiz((old)=>{
        let newArr=[...old]
        let item= newArr.find(item=>item.id==quiz.id)
        item.likes_count=data.likes_count
        item.dislikes_count=data.dislikes_count
        item.liked=data.liked
        item.disliked=data.disliked
        return newArr
       })
       }}  className="like-icon" style={{display:'flex',gap:10,alignItems:"center"}}>
        < ThumbDownIcon style={{color:quiz.disliked?"rgb(20,150,200)":"rgb(200,200,200)"}}/>
        {quiz.dislikes_count?quiz.dislikes_count:0}
       
       </div>
    {user && user.logged && quiz.favorite===0 && <FavoriteBorderIcon onClick={async()=>{
        try{
           await addToFavorite(quiz.id)
           toast.success("تم إضافة الإختبار لقائمة المفضلة")
           setQuiz((old)=>{
            let new_quiz=[...old]
            new_quiz.find(item=>item.id==quiz.id).favorite=1
            return new_quiz
           })
        }catch(err){
             toast.error("حدث خطأ أثناء إضافة الإختبار للمفضلة")
        }
     }} className="fav-icon"/>}
    {user && user.logged &&quiz.favorite===1 &&<FavoriteIcon
     onClick={async()=>{
      try{
         await removeFromFavorite(quiz.id)
         toast.success("تم إزالة الإختبار من قائمة المفضلة")
         setQuiz((old)=>{
          let new_quiz=[...old]
          new_quiz.find(item=>item.id==quiz.id).favorite=0
          return new_quiz
         })
      }catch(err){
           toast.error("حدث خطأ أثناء إزالة الإختبار من المفضلة")
      }
   }}
     className="fav-icon"
     />}
     {user && user.logged && user.type=="teacher"&& user.active && quiz.is_my_quiz &&<div>
      {quiz.is_active==1 &&<button onClick={()=>{
        toggleQuiz(quiz.id)
        setQuiz((old)=>{
          let new_quiz=[...old]
          new_quiz.find(el=>el.id==quiz.id).is_active=0
          return new_quiz
        })
      }}>إلغاء التفعيل</button>}
      {quiz.is_active==0 &&<button onClick={()=>{
        toggleQuiz(quiz.id)
        setQuiz((old)=>{
          let new_quiz=[...old]
          new_quiz.find(el=>el.id==quiz.id).is_active=1
          return new_quiz
        })
      }}>تفعيل</button>}
      </div>}
      </CardActions>
      
    </Card>
  );
}