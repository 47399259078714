import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom"
import { updateToken } from '../util/token';
import { useSearchParams } from 'react-router-dom';
///store Arabic words in english constants
const searchForQuiz="ابحث عن اختبار"
const generateQuiz="إنشاء اختبار"
const generateQuiz2="(الصفحة الجديدة) إنشاء اختبار"
const contactUS="تواصل معنا"
const personalPage="الصفحة الشخصية"
const profileSettings="إعدادات الحساب"
const myQuizes="إختباراي"
const signOUT="تسجيل الخروج"
const signIN="تسجيل الدخول"
const trainingPage="مركز التدريب"
const SearchActivities="نشاطات البحث الشائعة"
const pages=[
  {label:contactUS,route:"/contactus",states:[true,false],types:["student","teacher"]},
  {label:generateQuiz,route:"/generate",states:[true],types:["teacher"]},
  {label:generateQuiz2,route:"/generate2",states:[true],types:["teacher"]},
  {label:searchForQuiz,route:"/",states:[true,false],types:["student","teacher"]},
  {label:trainingPage,route:"/teachertraining",states:[true],types:["teacher"]},
   {label:SearchActivities,route:"/searchactivities",states:[true],types:["teacher"]},
   {label:"الإشعارات",route:"/notificationpage",states:[true],types:["teacher"]},
   {label:"إعدادات الحساب",route:"/teacherpagesetting",states:[true],types:["teacher"]}
]
const settings=[
  {label:"مفضلتي",route:"/favorites",states:[true],types:["student","teacher"]},

  {label:myQuizes,route:"/dashboard",states:[true],types:["teacher"]},
  {label:signOUT ,route:"/",states:[true],types:["student","teacher"],action:()=>{
    updateToken({token:"",logged:false, type:"",is_active:false})
  }},
  {label:"إعدادات الحساب",route:"/teacherpagesetting",states:[true],types:["teacher"]},
  {label:signIN ,route:"/login" ,states:[false],types:["student","teacher"]},
  
]

function generateInitials(username){
  if(!username) return "";
  return username.split(" ").map(item=>item[0]).join(" ")
}

function Navbar({user}) {
 

  const [params]=useSearchParams()

  let isCreateAccount=params.get("state")
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigator=useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
   
    navigator(page.route)
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
 
    if(setting.action){
         setting.action()
    }
    setAnchorElUser(null);
    navigator(setting.route)
  };
  const filterPages=(page,index)=>{
  
       return page?.states?.includes(user.logged) && (!user.logged || page.types.includes(user.type))
   
  }
  const filterSettings=(setting,index)=>{ 
   

      return setting.states.includes(user.logged) && (!user.logged || setting.types.includes(user.type));
  
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.filter(filterPages).map((page,index) => {
                
                return (<MenuItem  key={page} onClick={()=>handleCloseNavMenu(page)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>)
              })}
            </Menu>
          </Box>
         
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
             منصة إختبار
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.filter(filterPages).map((page,index) => (
              <Button
                key={page}
                onClick={()=>handleCloseNavMenu(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                
                {page.label}
              </Button>
            ))}
          </Box>
        
          <Box sx={{ flexGrow: 0,flexDirection:"row",display:"flex" }}>
            {user.logged==false&&isCreateAccount !=2 && <Button onClick={()=>{
              navigator("/login?state=2")
            }} sx={{  mx: 3, color: 'white', display: 'block' }}>إنشاء حساب</Button>}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {user&& user.logged &&<Avatar alt="Remy Sharp"  >
                  { generateInitials(user.username) }
                  </Avatar>}
                  {!user.logged&&<Avatar alt="Remy Sharp"></Avatar>}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.filter(filterSettings).map((setting,index) => (
                <MenuItem  key={setting} onClick={()=>{
                  handleCloseUserMenu(setting)
                
                
                }}>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default  Navbar;
