import { useState } from "react";
import { Button,TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import {Paper} from "@mui/material";
import { changePassword, sendPasscode } from "../API/user";
import { typography } from "@mui/system";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: 1,
        width: '25ch',
      },
    },
  }));
const ResetPassword=()=>{
    const classes = useStyles();
    const [email,setEmail]=useState("")
 
    const [password, setPassword] = useState('');
    const [passcode,setPasscode]=useState("")
    const [confirm,setConfirm]=useState("")
    const [error,setError]=useState("")
    const handleSignIn = async() => {
     
    };
    const navigator=useNavigate();
  
    return (
        <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",gap:10,alignItems:"center",justifyContent:"center",padding:5,textAlign:"right",direction:"rtl"}}>
    <Paper sx={{padding:4}} elevation={3}>
      <form className={classes.form}>
        <TextField
          label={"البريد الإلكتروني"}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button onClick={async()=>{
            try{
              await sendPasscode(email);
              toast.success("تم إرسال رمز الاستعادة بنجاح")
            }catch(err){
              toast.error(err.message)

            }
        }}  variant="contained">أرسل رمز المرور</Button>
       
      </form>
      </Paper>
      <div>بعد استلام رمز المرور استخدمه من أجل تغيير كلمة المرور</div>
      <Paper sx={{padding:4}} elevation={3}>
      <form className={classes.form}>
      <TextField
          label={"رمز المرور"}
          type="text"
          value={passcode}
          onChange={(e) =>setPasscode(e.target.value)}
        />
        <TextField
          label={"كلمة المرور الجديدة"}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
         <TextField
          label={"توكيد كلمة المرور"}
          type="password"
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
        />
        {error !="" &&<div style={{color:"red"}}>{error}</div>}
       
        <Button onClick={async()=>{
          try{
            if(password.length<8 || password.length>50){
              throw new Error("كلمة السر يجب أن تكون بين ثمانية حروف إلى خمسين حرف")
            }
            if(password !=confirm){
              throw new Error("كلمة المرور لا تتوافق مع توكيد كلمة المرور")
            }
           await changePassword(passcode,password);
           navigator("/login")
          }catch(err){
           toast.error(err.message)
          }
        }}  variant="contained" color="primary" >
          تعديل كلمة المرور
        </Button>
      </form>
      </Paper>
      </div>
    );
    
}

export default ResetPassword;