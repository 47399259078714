import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { Howl } from 'howler';
import { icons } from "../util/icons";
const NotificationComp=({msg,icon})=>{

    const playSoundNotification = () => {
        const sound = new Howl({
          src: ['/media/notification.mp4'],
        });
    
        sound.play();
      };
      const playSoundDimiss = () => {
        const sound = new Howl({
          src: ['/media/dismiss.wav'],
        });
    
        sound.play();
      };

  return  <Button variant="contained" color="success" onClick={()=>{
    playSoundNotification();
         toast(
          (t) => (
            <span>
              {icons.find(item=>item.id===icon).icon} {msg}
              <button
                onClick={() => {
                  toast.dismiss(t.id)
                     playSoundDimiss();
                }}
                style={{
                  marginLeft: '10px',
                  backgroundColor: 'lightblue',
                  color: 'black',
                  border: 'none',
                  padding: '8px 12px',
                  cursor: 'pointer',
                  margin:"10px"
                }}
              >
                تم
              </button>
            </span>
          ),
          {
            duration: Infinity,
            position: 'top-right',
            style: {
              border: '1px solid blue',
              padding: '16px',
              color: 'black',
              backgroundColor: '#ffffff',
              textAlign:"right",
              direction:"rtl"
            },
          }
        );
  }}>
  Show test
</Button>
}

export default NotificationComp