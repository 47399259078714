import { FormControl, Paper } from "@mui/material"
const TeacherAgreement=()=>{
    return <div 
    style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center",padding:5}}
    ><Paper sx={{width:"90%",textAlign:"right",direction:"rtl",padding:5}} elevation={3}>
          <h1 style={{textAlign:"center"}}>اتفاقية الاستخدام لصانع المحتوى</h1>
       
          <div>
        <p>صانع المحتوى يوافق على البنود التالية:</p>
        <ol>
            <li>صانع المحتوى ليس موظفاً لدى شركة اختبار ولا يملك أي حقوق وظيفية، أي لا يملك ضماناً صحياً ولا يملك ضماناً اجتماعياً ولا يتضمن العمل أي أجور تقاعد أو أجور نهاية خدمة.</li>
            <li>العقد الخاص بالعلاقة مع صانع المحتوى قد يتم تعديله لاحقاً وسيتم إعلام صناع المحتوى بأي تغيير بشرط ألا يؤثر التغيير على أحد حقوق صانع المحتوى المتفق عليها في عقد سابق.</li>
            <li>يحق لشركة اختبار فسخ العقد في أي وقت كان من دون سابق إنذار مع إعطاء صانع المحتوى جميع حقوقه المادية المستحقة.</li>
            <li>شركة اختبار تشارك صانع المحتوى بالملكية الفكرية لأي اختبار يتم صناعته على المنصة.</li>
            <li>صانع المحتوى يلتزم بأن يكون جميع المحتوى المرفوع من قبله إلى المنصة يتوافق مع شروط المحتوى الخاصة بالمنصة.</li>
            <li>أي تجاوز لحقوق الملكية الفكرية من قبل صانع المحتوى قد يعرضه للملاحقة القانونية وتعويض الأضرار.</li>
            <li>صانع المحتوى يسمح لمنصة إختبار بمراقبة سلوكه على المنصة ضمن حدود الحاجة الوظيفية مما يتضمن مراقبة معدل الاختبارات المولدة ومراقبة عدد التفاعلات على اختباراته، وفي حال إدخال أي مشعر إضافي للمراقبة سيتم إخطار صانع المحتوى بالتغيير.</li>
            <li>صانع المحتوى لا يستخدم المنصة كوسيلة للترويج الشخصي ونشر المعلومات أو الحسابات الشخصية ضمن الاختبارات، وأي مخالفة سيتم فسخ العقد وحذف جميع الاختبارات ذات العلاقة بالمخالفة.</li>
            <li>صلاحية العقد لمدة عام من توقيعه ويتم تجديد توقيع العقد كل عام</li>
            <li>يحتفظ كل طرف بحقوق الملكية الفكرية الخاصة به، ولكن يمنح الطرف الآخر حق استخدام واستغلال المحتوى المنشأ في إطار هذا العقد وفقاً للشروط المتفق عليها.</li>
            <li>يلتزم الطرفان بالحفاظ على سرية المعلومات السرية والبيانات الشخصية التي يتم تبادلها في إطار هذا العقد.</li>
            <li>في حالة نشوء نزاع، يجب على الطرفين محاولة تسويته بشكل ودي، وإذا لم يتم التوصل إلى حل، يتم اللجوء إلى التحكيم أو المحاكم المختصة.</li>
            <li>يلتزم كل طرف بالوفاء بالتزاماته القانونية والضريبية والتأمينية المتعلقة بهذا العقد.</li>
          
        </ol>
    </div>
    </Paper> 
    </div>
}

export default TeacherAgreement