import { useState,useEffect } from "react"


import LoginForm from "./components/LoginForm";
import { Button } from "@mui/material";
import TabsComp from "@components/TabsComp";
import TeacherPage from "./pages/TeacherPage";
import UserPage from "./pages/UserPage";
import QuizPage from "./pages/QuizPage";
import ContactUsPage from "./pages/ContactUsPage";
import ClaimsPage from "./pages/ClaimsPage";
import SaveDataBasePage from "./pages/SaveDataBasePage";
import NotificationPage from "./pages/NotificationPage";
import { verifyToken} from "@api/admin";
/*
import { Container,Select,InputLabel,MenuItem, Box,Modal, TextField,Paper, Button, Typography, Avatar, Grid } from '@mui/material';

import { logIn ,verifyToken,addTeacher,getTeachersList ,getUsersList,updateTeacherActivity } from "../../API/admin";

import { loadAllQuizes,toggleQuiz,deleteQuiz } from "../API/quiz";
import { downloadDatabase ,uploadDatabase,loadBackUpDirectoryFiles,deleteFile,moveToProduction} from "../API/database";
import { icons } from "../util/icons";
import NotificationComp from "../components/NotificationComp";
import { addNotification } from "../API/notification";
import toast from "react-hot-toast";
import { signIn } from "../API/user";
const theme = createTheme();
*/

export default function Admin(){
    const [user,setUser]=useState(false);


    const validToken=async()=>{
        try{
            let token=localStorage.get("token")
            let {valid}=await verifyToken(token)
            return valid
        }catch(err){
            return false
        }
    }
    useEffect(()=>{
         if(localStorage.getItem("token")){
            if(validToken()){
                setUser(true)
            }
         }
    },[])

    if(!user){
       return <LoginForm user={user} setUser={setUser} />
    }else{
       return <div>
        <Button onClick={()=>{
            setUser(false)
            window.localStorage.setItem("token","")
        }}>LogOut</Button>
        <TabsComp tabs={[
             {title:"Teachers",comp:<TeacherPage/>},
             {title:"Students",comp:<UserPage />},
             {title:"Quizes",comp:<QuizPage/>},
             {title:"Contact us",comp:<ContactUsPage/>},
             {title:"Copy right claims",comp:<ClaimsPage/>},
             {title:"Save database",comp:<SaveDataBasePage/>},
             {title:"Notification page",comp:<NotificationPage/>}

        ]} />
      
        </div> 
    }
   
}