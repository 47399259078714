import { Link, useParams } from "react-router-dom"
import { useState,useEffect } from "react"
import { Button ,Box,Stack,Modal,Typography, Input,InputAdornment,InputLabel} from "@mui/material"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from "react-hot-toast"
import { maxHeight } from "@mui/system";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    maxWidth:"90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY:"scroll",
    maxHeight:"90vh"
  };
  
const platforms=[
    {
      label:"Facebook",component:<FacebookIcon/> ,source:"fb"
    },
    {
      label:"Youtube",component:<YouTubeIcon/>,source:"yt"
    },
    {
      label:"X",component:<XIcon/> ,source:"x"
    },
    {
      label:"Whatsapp",component:<WhatsAppIcon/>,source:"wa"
    },
    {
      label:"Telegram",component:<TelegramIcon/> ,source:"tg"
    },
    {
      label:"Instagram",component:<InstagramIcon/> ,source:"in"
    },
    {
      label:"Linkedin",component:<LinkedInIcon/>,source:"li"
    },
    {
      label:"Source 1",component:<ContentCopyIcon/>,source:"s1"
    },
    {
      label:"Source 2",component:<ContentCopyIcon/>,source:"s2"
    },
    {
      label:"Source 3",component:<ContentCopyIcon/>,source:"s3"
    },
    {
      label:"Source 4",component:<ContentCopyIcon/>,source:"s4"
    },
  ]
  function getBaseURL() {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/`;
  }
  function getShareLink(quiz_id,source){
     return `https://eiztebiar-front-end2.onrender.com/quiz?quiz_id=${quiz_id}&s=${source}`
  }
  
function ShareModal({open,setOpen,quiz_id}){
   
    let location=getBaseURL()
    const [url,setURL]=useState(location+"quiz/")
    const [type,setType]=useState(window.localStorage.getItem("user_type"))
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   async function copyLink(platform){

    try{
     await navigator.clipboard.writeText(getShareLink(quiz_id,platform));
     toast.success("Copied to memory")
    }catch(err){
        console.log(err)
        toast.error("Error happened")
    }
   }
    return (
      <div>
       
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{overflowY:"scroll"}}
        >
          <Box sx={style}>
            <Typography sx={{textAlign:"right"}} id="modal-modal-title" variant="h6" component="h2">
              مشاركة الإختبار
            </Typography>
     <div style={{display:"flex",flexDirection:"column",alignItems:type=="teacher"?"start":"end",justifyContent:"center",gap:"5px"}}>
          {type=="teacher"&&platforms.map(item=>{
            return <>
            <>
              <InputLabel htmlFor={item.source}>
          {item.label}
        </InputLabel>
            
            <Input
            fullWidth
            sx={{width:"100%"}}
            value={getShareLink(quiz_id,item.source)}
            id={item.source} label={item.label} variant="standard" 
            endAdornment={
                 <InputAdornment className="share-icon" onClick={()=>{
                  copyLink(item.source)
                 }} position="end">
                 <ContentCopyIcon/>
                 </InputAdornment>
              
             }/></>
             </>
          })}
          

          {type !="teacher" &&(
            <>
             <InputLabel sx={{textAlign:"right",marginRight:"0px"}} htmlFor={"sharelink"}>
          نسخ الرابط
        </InputLabel>
            
            <Input
            fullWidth
            sx={{width:"100%"}}
            value={getShareLink(quiz_id,"student")}
            id={"sharelink"}  variant="standard" 
            endAdornment={
                 <InputAdornment className="share-icon" onClick={()=>{
                  copyLink("student")
                 }} position="end">
                <ContentCopyIcon/>
                 </InputAdornment>
              
             }/>
            </>
          )}
          </div>
           
      <Button sx={{m:2}} color="error" variant="contained" onClick={()=>setOpen(false)}>
        أغلق
      </Button>

          
          </Box>
        
        </Modal>
      </div>
    );

}
export default ShareModal