import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"


export const get_teacher_account=async()=>{
  try{
    const {data}=await axios.get(serverURL+"/teacher_account",{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
         }
    })
    console.log(data)
    return data
  }catch(err){
      console.log(err)
      throw new Error("")
  }
}


export const get_teacher_account_by_id=async(id)=>{
    try{
      const {data}=await axios.get(serverURL+"/teacher_account/"+id)
      const result2=(await get_teacher_quizes(id))
      console.log(result2)
      return {...data,quizes:result2.quizes}
    }catch(err){
        console.log(err.message)
        throw new Error("")
    }
  }

export const update_teacher_account=async(data)=>{
    
    try{ 
        await axios.put(serverURL+"/teacher_account",data,{
            headers:{
                Authorization:window.localStorage.getItem("usertoken")
             }
        })
        
    }catch(err){
         console.log(err.message)
         throw new Error("")
    }
}

export const get_teacher_quizes=async(id)=>{
  try{ 
   const {data}= await axios.get(serverURL+"/teacher_account/quizes/"+id)
    return data
}catch(err){
     console.log(err)
     throw new Error("")
}

}