import axios from "axios"
import { updateToken } from "../util/token"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"



export const signInWithTelegram=async(user)=>{
 try{

  const {data} =await axios.post(serverURL+"/auth/telegram",user)
  console.log(data)
  if(data && data.token){
    updateToken(data)
    return true
  }
  return false
 }catch(err){
    console.log(err)
    return false
 }
}