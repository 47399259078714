import { useState,useEffect } from "react"
import { getUsersList } from "@api/admin";
function UserPage(){

    const [users,setUsers]=useState([])


    const loadUsers=async()=>{
         const data=await getUsersList();
         setUsers(data)
    }
    useEffect(()=>{

       loadUsers();
    },[])


  
 

    return <>

    <table>
      <tr>
        <th>Username</th>
        <th>Password</th>
        <th>Is active</th>
      </tr>
      {users && users.map(item=>{
         return <tr>
          <td>{item.username}</td>
          <td>{item.password}</td>
          <td>{item.is_active}</td>
         </tr>
      })}
    </table>

       
    
    </>
}

export default UserPage;