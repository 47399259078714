import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom"
import ReactGA from "react-ga4";

ReactGA.initialize("G-LW6RTMZKMN");

ReactDOM.render(
   <StrictMode>
    <BrowserRouter basename = {process.env.PUBLIC_URL}>
    <App />
 </BrowserRouter>
 </StrictMode>,
    document.getElementById('root')
  )

