import { useState,useEffect } from "react";
import { Select,InputLabel,MenuItem,  TextField,Paper, Button, Typography, Avatar, Grid } from '@mui/material';
import { getTeachersList } from "@api/admin";
import { addNotification } from "@api/notification";
import NotificationComp from "@components/NotificationComp";
import { icons } from "util/icons";

function NotificationPage(){

    const [teachers,setTeachers]=useState([])
    const [selectedTeachers,setSelectedTeachers]=useState([])
    const loadTeachers=async()=>{
      const data=await getTeachersList();
      setTeachers(data);
      setSelectedTeachers(new Array(data.length).fill(false))
  }
  useEffect(()=>{
    loadTeachers();
  
  },[])
  
  
  const SendNotificationForm=()=>{
   const [notification,setNotification]=useState({
    msg:"",
    icon:2
  
   })
    const handleSubmit=async(e)=>{
      e.preventDefault();
      try{
        let teachersID=teachers.filter((item,index)=>{
          return selectedTeachers[index]
        }).map(item=>item.id)
        console.log(teachersID)
       await addNotification(notification.msg,notification.icon,teachersID);
      }catch{
  
      }
    }
    const handleChange=(e)=>{
        setNotification((old)=>{
          return {...old,[e.target.name]:e.target.value}
        })
    }
  
    return   <Paper elevation={3} style={{ padding: '20px' }}>
    <Typography variant="h5" gutterBottom>Notification Form</Typography>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="content"
            name="msg"
            value={notification.msg}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
        <InputLabel id="demo-simple-select-label">Icon</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={notification.icon}
      label="Icon"
      onChange={(e)=>{
        
        setNotification((old)=>{
          return {...old,icon:e.target.value}
        })
      }}
    >
  
      {icons.map(icon=>{
        return    <MenuItem value={icon.id}>{icon.icon}</MenuItem>
      })}
    </Select>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Send Notification
          </Button>
        </Grid>
      </Grid>
    </form>
    <Paper elevation={3} sx={{m:2,p:2}}>
    <div style={{ 
                    border: '1px solid blue',
                    padding: '16px',
                    color: 'black',
                    backgroundColor: '#ffffff',
                    textAlign:"right",
                    direction:"rtl",
                    width:"fit-content",
                    margin:"auto",
                    borderRadius:10
                  }}>
                    {icons.find(item=>item.id===notification.icon).icon} {notification.msg}
                    <button
                     
                      style={{
                        marginLeft: '10px',
                        backgroundColor: 'lightblue',
                        color: 'black',
                        border: 'none',
                        padding: '8px 12px',
                        cursor: 'pointer',
                        margin:"10px"
                      }}
                    >
                      تم
                    </button>
                  </div>
    </Paper>
    <NotificationComp msg={notification.msg} icon={notification.icon}/>
  </Paper>
  }
  
  
  
  
  
  return <div>
        
            
     <Button style={{margin:2}} variant="contained" onClick={()=>{
      setSelectedTeachers((old)=>{
       let new_arr=old.map(item=>true)
       return new_arr
      })
     }}>Select all</Button>
  
  <Button  style={{margin:2}}  variant="contained"  onClick={()=>{
      setSelectedTeachers((old)=>{
       let new_arr=old.map(item=>false)
       return new_arr
      })
     }}>Select none</Button>
        <table style={{width:"100%",margin:3}}>
          <tr>
              <th>Teacher name</th>
          
              <th >include</th>
              <th>Id</th>
          </tr>
      {teachers && teachers.map((teacher,index)=>{
          return <tr>
              <td>{teacher.email}</td> 
              <td> <input type="checkbox" onClick={()=>{
                setSelectedTeachers((old)=>{
                  let new_selection=[...old]
                  new_selection[index]=!new_selection[index]
                  return new_selection
                })
  
              }} checked={selectedTeachers[index]}/> </td>
             <td>{teacher.id}</td>
          </tr>
      })}
      </table>
         
      <SendNotificationForm/>
    </div>
  }
  export default NotificationPage