import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
const theme = (outerTheme) =>
    createTheme({
      direction: 'rtl',
      typography:{
          fontFamily:"cocon-next-arabic"
      }
    });
  
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });
  
  
  function ArabicTextField({label,placeholder,value,onChange}){
      return <CacheProvider  value={cacheRtl}>
      <ThemeProvider theme={theme}>
      <div dir="rtl">
        <TextField
         value={value}
         onChange={(e)=>{
          onChange(e)
         }}
      
          label={label}
          placeholder={placeholder}
          variant="filled"
  
          sx={{width:"100%"}}
          multiline
        />
      </div>
    </ThemeProvider>
  </CacheProvider>
  
      
      
  }

  export default ArabicTextField