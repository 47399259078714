
import { TextField,Box,Paper,FormControl,FormLabel,RadioGroup,Radio,FormControlLabel} from "@mui/material"
import Question from "../components/Question"
import { isValidImg } from "../../../util/images"



function RenderQuestionPage({quiz,setQuiz,page}){
    return <div style={{
      width:"100%",
      height:"fit-content",
      minHeight:"80vh",
      display:"flex",
      alignItems:"flex-start",
      justifyContent:"space-around"
      }}>
        <Paper elevation={3} style={{padding:6,margin:6,width:"100%",height:"100%",backgroundColor:"rgb(220,230,255)"}}>
  
              <h1>النتيجة</h1>
              <div style={{fontSize:24}}>كيف سيبدو السؤال على المنصة</div>
              <div style={{
        
    }}>
              <Question question={quiz.questions[page]}/>
                
              </div>
  
        </Paper>
  
        <Paper elevation={3} style={{margin:6,padding:6,width:"100%",height:"100%",backgroundColor:"rgb(220,255,230)"}}>
            <h1>المحرر</h1>
            <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
        display:"flex",
        flexDirection:"column"
      }}
      noValidate
      autoComplete="off"
    >
      <TextField 
      error={quiz.questions[page].statment.trim()==""}
      helperText={quiz.questions[page].statment.trim()==""?"يجب إضافة نص للسؤال":""}
      onChange={(e)=>{
         
        let new_questions=quiz.questions.map(item=>item)
        new_questions[page]={...new_questions[page],statment:e.target.value}
        let new_quiz={...quiz,questions:new_questions}
       setQuiz(new_quiz)
        
         
         
  
      }} value={quiz.questions[page].statment}   style = {{width: "95%",direction:"rtl",textAlign:"right"}} label="نص السؤال" variant="outlined" fullWidth />
      
      <TextField 
      error={quiz.questions[page].options[0].trim()==""}
      helperText={quiz.questions[page].options[0].trim()==""?"الخيار الأول إلزامي":""}
      
      onChange={(e)=>{
        let new_questions=quiz.questions.map(item=>item)
        let new_options=new_questions[page].options.map(item=>item)
        new_options[0]=e.target.value
        new_questions[page]={...new_questions[page],options:new_options}
        let new_quiz={...quiz,questions:new_questions}
       setQuiz(new_quiz) 
       
      }}  value={quiz.questions[page].options[0]}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="الخيار الأول" variant="outlined" fullWidth  />
       <TextField
        error={quiz.questions[page].options[1].trim()==""}
        helperText={quiz.questions[page].options[1].trim()==""?"الخيار الثاني إلزامي":""}
        
       
       onChange={(e)=>{
          let new_questions=quiz.questions.map(item=>item)
          let new_options=new_questions[page].options.map(item=>item)
          new_options[1]=e.target.value
          new_questions[page]={...new_questions[page],options:new_options}
          let new_quiz={...quiz,questions:new_questions}
         setQuiz(new_quiz) 
       
      }}  value={quiz.questions[page].options[1]}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="الخيار الثاني" variant="outlined" fullWidth  />
      <TextField onChange={(e)=>{
        
        let new_questions=quiz.questions.map(item=>item)
        let new_options=new_questions[page].options.map(item=>item)
        new_options[2]=e.target.value
        new_questions[page]={...new_questions[page],options:new_options}
        let new_quiz={...quiz,questions:new_questions}
       setQuiz(new_quiz) 
      }}  value={quiz.questions[page].options[2]}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="الخيار الثالث" variant="outlined" fullWidth  />
      
      <TextField onChange={(e)=>{
         let new_questions=quiz.questions.map(item=>item)
         let new_options=new_questions[page].options.map(item=>item)
         new_options[3]=e.target.value
         new_questions[page]={...new_questions[page],options:new_options}
         let new_quiz={...quiz,questions:new_questions}
        setQuiz(new_quiz) 
      }}  value={quiz.questions[page].options[3]}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="الخيار الرابع" variant="outlined" fullWidth  />
      
      <TextField onChange={(e)=>{
         let new_questions=quiz.questions.map(item=>item)
         let new_options=new_questions[page].options.map(item=>item)
         new_options[4]=e.target.value
         new_questions[page]={...new_questions[page],options:new_options}
         let new_quiz={...quiz,questions:new_questions}
        setQuiz(new_quiz) 
      }}  value={quiz.questions[page].options[4]}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="الخيار الخامس" variant="outlined" fullWidth  />
      
  
      
      <TextField onChange={(e)=>{
         let new_questions=quiz.questions.map(item=>item)
      
         new_questions[page]={...new_questions[page],reasoning:e.target.value}
         let new_quiz={...quiz,questions:new_questions}
        setQuiz(new_quiz) 
      }}  value={quiz.questions[page].reasoning}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="التعليل" variant="outlined" fullWidth  />
      
     
     
  
      <TextField 
      
      error={quiz.questions[page].imageURI !="" && !isValidImg(quiz.questions[page].imageURI)}
      helperText={(quiz.questions[page].imageURI !="" && !isValidImg(quiz.questions[page].imageURI))?"صيغة الصورة غير صحيحة":""}
      onChange={(e)=>{
         let new_questions=quiz.questions.map(item=>item)
         let new_options=new_questions[page].options.map(item=>item)
        
         new_questions[page]={...new_questions[page],imageURI:e.target.value}
         let new_quiz={...quiz,questions:new_questions}
        setQuiz(new_quiz) 
      }}  value={quiz.questions[page].imageURI}  style = {{width: "95%",direction:"rtl",textAlign:"right"}}  label="صورة السؤال" variant="outlined" fullWidth  />
      {(quiz.questions[page].imageURI !="" && !isValidImg(quiz.questions[page].imageURI))&&<div style={{width:"100%"}}>
        راجع الدرس الثاني من مركز التدريب
        </div>}
  
      <FormControl style={{width:"100%"}} >
    <FormLabel id="demo-radio-buttons-group-label">الإجابة الصحيحة</FormLabel>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      value={quiz.questions[page].correct}
      name="radio-buttons-group"
     row={true}
     onChange={(e)=>{
      let new_questions=[...quiz.questions]
      new_questions[page]={...new_questions[page],correct:e.target.value}
      setQuiz({...quiz,questions:new_questions})
     }}
     
    >
      {["0","1","2","3","4"].map(item=>{
         if(item>1){
          if(quiz.questions[page].options[item].trim()=="") {
            if(quiz.questions[page].correct==item){
              let new_questions=[...quiz.questions]
              new_questions[page].correct="0"
              setQuiz({...quiz,questions:new_questions})
            }
             return <></>
          }
         }
   return <FormControlLabel  value={item} control={<Radio />} label={(1*item+1)+" الخيار"} />
      })}
     
    </RadioGroup>
  </FormControl>
  
  
        </Box>
  
        </Paper>
       
    </div>
  }
  
export default RenderQuestionPage