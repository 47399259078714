import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAITcn5VQtWoKIaOqpLHR_9Svt0SzroM-w",
  authDomain: "ekhtibar-8b838.firebaseapp.com",
  projectId: "ekhtibar-8b838",
  storageBucket: "ekhtibar-8b838.appspot.com",
  messagingSenderId: "219369798833",
  appId: "1:219369798833:web:308b792db499f972faf690"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
