import Navbar from "./Navbar";
import { createTheme } from '@mui/material/styles';
import { create } from 'jss';

import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import { ThemeProvider, CssBaseline } from '@mui/material';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Create a theme with right-to-left direction.
const theme = createTheme({
  direction: 'rtl', // Switching the direction to rtl
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  // Add other theme configurations if necessary
});/**/

function Header({user}) {
  return (
<header>
  <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
   
        <Navbar user={user} />
   
      </ThemeProvider>
  </StylesProvider>
     
</header>
  );
}

export default Header;