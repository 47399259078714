
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { useStateSession } from '../util/hooks';

const gradesMap={
    "0":"أطفال",
    "1":"أول ابتدائي",
    "2":"ثاني ابتدائي",
    "3":"ثالث ابتدائي",
    "4":"رابع ابتدائي",
    "5":"خامس ابتدائي",
    "6":"سادس ابتدائي",
    "7":"سابع اعدادي",
    "8":"ثامن اعدادي",
    "9":"تاسع اعدادي",
    "10":"عاشر ثانوي",
    "11":"حادي عشر ثانوي",
    "12":"بكالوريا ثانوي",
    "13":"جامعي",
    "14":"ثقافة عامة"
}
const materialsMap={
    "0":"لغة أجنبية",
    "1":"اللغة العربية",
    "2":"رياضيات",
    "3":"فيزياء",
    "4":"كيمياء",
    "5":"بيولوجيا",
    "6":"تاريخ",
    "7":"جغرافيا",
    "8":"تربية دينية",
    "9":"علوم الحاسب",
    "10":"الفلسفة",
    "11":"ثقافة عامة",
    "12":"مادة جامعية"
}

const levelsMap={
    "0":"سهل",
    "1":"متوسط",
    "2":"صعب"
}

export default function SearchFilter() {
  const [filter,setFilter]=useStateSession({
     grades:[],
     materials:[],
     levels:[]

  },"searchFilter")
 
  return (
    <div style={{padding:6}}>
        <h3>خيارات البحث</h3>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{textAlign:"right",direction:"rtl",width:"100%"}}>المرحلة الدراسية</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {Object.keys(gradesMap).map(item=>{
               
                return <div onClick={()=>{
                    let grades=filter.grades
                    
                    if(grades.find(el=>el==item)){
                       grades=grades.filter(el=>el!=item)
                    }else{
                        grades=[...grades,item]

                    }
                    setFilter({...filter,grades})
                }} className={'search-filter-option'+" "+(filter?.grades?.find(el=>el==item)?"active-filter":"inactive-filter")} >{gradesMap[item]}</div>
            })}
          
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={{textAlign:"right",direction:"rtl",width:"100%"}}>المادة الدراسية</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {Object.keys( materialsMap).map(key=>{
                return <div  
                onClick={()=>{
                    let materials=filter.materials
                    
                    if(materials.find(el=>el==key)){
                       materials=materials.filter(el=>el!=key)
                    }else{
                        materials=[...materials,key]

                    }
                    setFilter({...filter,materials})
                }} className={'search-filter-option'+" "+(filter?.materials?.find(el=>el==key)?"active-filter":"inactive-filter")}
                
                
                >{materialsMap[key]}</div>
            })}
         
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography  sx={{textAlign:"right",direction:"rtl",width:"100%"}}>مستوى الصعوبة</Typography>
        </AccordionSummary>
        <AccordionDetails>
     

        {Object.keys(levelsMap).map(key=>{
                return <div  
                onClick={()=>{
                    let levels=filter.levels
                    
                    if(levels.find(el=>el==key)){
                       levels=levels.filter(el=>el!=key)
                    }else{
                        levels=[...levels,key]

                    }
                    setFilter({...filter,levels})
                }} className={'search-filter-option'+" "+(filter?.levels?.find(el=>el==key)?"active-filter":"inactive-filter")}
                
                
                >{levelsMap[key]}</div>
            })}
   
        </AccordionDetails>
      </Accordion>

      <Button variant='contained' color="success" sx={{p:1,mt:1}}   >ابحث</Button>
    </div>
  );
}
