import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { InputAdornment } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RadioGroup from '@mui/material/RadioGroup';

const theme = (outerTheme) =>
  createTheme({
    direction: 'rtl',
    typography:{
        fontFamily:"cocon-next-arabic",
        fontSize:"50px"
    }
  });

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


function ArabicText({label,placeholder,index,hasRadio,hasDelete,value,onChange, onDeleteClick}){
    return <CacheProvider  value={cacheRtl}>
    <ThemeProvider theme={theme}>
    <div dir="rtl">
      <TextField
      style={{fontSize:25}}
       value={value}
       onChange={(e)=>{
        onChange(e)
       }}
    
        label={label}
        placeholder={placeholder}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {hasDelete&&<DeleteForeverIcon onClick={ onDeleteClick} style={{cursor:"pointer"}}  />}
              </InputAdornment>
            ),
            endAdornment:(
                <InputAdornment position="end">
                {hasRadio&&<Radio value={index}/>}
              </InputAdornment>
            )
          }}
        variant="filled"

        sx={{width:"100%"}}
        multiline
      />
    </div>
  </ThemeProvider>
</CacheProvider>

    
    
}


export default function AddQuestion({newQuestion,setNewQuestion,isEnglish}){
  



    return  <>
        <Box component="div" sx={{m:4, width:300 ,border:"1px solid grey"}}>
        {!isEnglish &&<ArabicText value={newQuestion.statment} onChange={(e)=>{
            setNewQuestion({...newQuestion,statment:e.target.value})
        }} label={"السؤال"} placeholder={"نص السؤال"} />}

        {isEnglish&& <TextField
        value={newQuestion.statment}  onChange={(e)=>{
          setNewQuestion({...newQuestion,statment:e.target.value})
         
      }}
      variant="filled"

      sx={{width:"100%"}}
      multiline
         label={"Question"} placeholder={"Question statment"}
        />}

{newQuestion.imageURI && <img style={{width:"100%"}}  src={newQuestion.imageURI} />}
{!isEnglish&&<ArabicText value={newQuestion.imageURI} onChange={(e)=>{
            setNewQuestion({...newQuestion,imageURI:e.target.value})
        }} label={"صورة للاختبار"} placeholder={"صورة للاختبار"} />}
{isEnglish&& <TextField 
 value={newQuestion.imageURI} onChange={(e)=>{
  setNewQuestion({...newQuestion,imageURI:e.target.value})}}
  label={"Question image"} placeholder='Question image'

  variant="filled"

  sx={{width:"100%"}}
  multiline
/>}

        
    <Box component="div" sx={{width:300 ,border:"1px solid black"}}>
        < RadioGroup
         defaultValue={0}
         value={newQuestion.correct}
         onChange={(e)=>{
            setNewQuestion({...newQuestion,correct:e.target.value})
         }}
         name="radio-buttons-group"
        >
   {newQuestion.options.map((item,index)=>{
    if(!isEnglish){
    return <ArabicText value={item} 
    onChange={(e)=>{
        let new_options=[...newQuestion.options]
        new_options[index]=e.target.value
        setNewQuestion({...newQuestion,options:new_options})
    }}
    onDeleteClick={()=>{
        if(newQuestion.options.length>2){
            setNewQuestion({...newQuestion
                ,options:newQuestion.options.filter((item,inx)=>inx!=index)})
        }
    }}
    index={index} label={"خيار"} placeholder={"نص الاختيار"} hasRadio hasDelete/>
   
    }else{
     return <TextField value={item} 
    onChange={(e)=>{
        let new_options=[...newQuestion.options]
        new_options[index]=e.target.value
        setNewQuestion({...newQuestion,options:new_options})
    }}

    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {<DeleteForeverIcon onClick={ ()=>{
        if(newQuestion.options.length>2){
            setNewQuestion({...newQuestion
                ,options:newQuestion.options.filter((item,inx)=>inx!=index)})
        }
    }} style={{cursor:"pointer"}}  />}
        </InputAdornment>
      ),
      endAdornment:(
          <InputAdornment position="end">
          {<Radio value={index}/>}
        </InputAdornment>
      )
    }}
  variant="filled"
  
    index={index} label={"Choice"} placeholder={"Choice text"} hasRadio hasDelete/>
    }
  
  })


  }
   </RadioGroup>
      <Button sx={{m:2,fontFamily:"cocon-next-arabic"}} variant="contained" onClick={()=>{
        if(newQuestion.options.length<5)
          setNewQuestion({...newQuestion,options:[...newQuestion.options,""]})
      }}    >
     {isEnglish?"Add choice":"إضافة خيار"}

      </Button>
        </Box>
        </Box>
    </>
}