import { useSearchParams } from "react-router-dom";
import { useStateSession } from "../util/hooks"
import RenderPage from "./GenerateQuizPage/pages/RenderPage";
import Navbar from "./GenerateQuizPage/components/Navbar";
import SwitchPage from "./GenerateQuizPage/components/SwitchPage";
import { Button } from "@mui/material";
const testState=JSON.parse(`
  {"material":5,"grades":[4,8,12],"level":"1","title":"عنوان الاختبار","description":"هذا الاختبار هو مجرد اختبار تجريبي من أجل التيستنغ و تطوير المنصة","imageThumbnail":"https://i.ibb.co/5sTjGD3/default-Ekhtibar-Image.jpg","questions":[{"statment":"السؤال الأول","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال الثاني","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال الثالث","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال الرابع","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال الخامس","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال السادس","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال السابع","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال الثامن","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال التاسع","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""},{"statment":"السؤال العاشر","options":["خيار صحيح","خيار خاطئ","","",""],"correct":"0","imageURI":"","reasoning":""}],"tags":["وسم أول","وسم ثاني","وسم ثالث","وسم رابع","وسم خامس"]}
  
  `)
export default function GenerateQuiz2(){
  let [searchParams, setSearchParams] = useSearchParams();
  const [quiz,setQuiz]=useStateSession({
    title:"",
    description:"",
    imageThumbnail:"",
    questions:new Array(10).fill(0).map(item=>{
      return {
        statment:"",
        options:["","","","",""],
        correct:"0",
        imageURI:"",
        reasoning:""
      }
    }),
    tags:[],
    grades:[],
    level:"",
    countries:[],
    material:"0"
  },"questions")

 
  let page=searchParams.get("page")
  if(!page){
    page="details";
  }
  
 

   return <div >
   <h1 style={{color:"orange", fontSize:15}}>هذه الصفحة قيد التجريب قد تحدث بعض الأخطاء</h1>
   <Navbar setQuiz={setQuiz}/>
   <RenderPage setSearchParams={setSearchParams} page={page} quiz={quiz} setQuiz={setQuiz} />
   <SwitchPage page={page} setSearchParams={setSearchParams} />
   <Button className="hide-button" style={{display:"none"}} onClick={()=>{
    setQuiz(testState)
   }} > Test</Button>
   </div> 
}