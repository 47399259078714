import { useState } from "react";



export const useStateSession=(initialValue,key)=>{
  
   let value;
   if(!localStorage.getItem(key)){
    localStorage.setItem(key,JSON.stringify(initialValue))
    
   }
    value=JSON.parse(localStorage.getItem(key))
    const [state,setState]=useState(value)
   function setValue(newValue){
      if(newValue){
        localStorage.setItem(key, JSON.stringify( newValue))
        setState(newValue)
      }
   }

   return [state,setValue]

}


