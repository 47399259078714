import { Button } from "@mui/material"

function SwitchPage({page,setSearchParams}){
 return <div style={{display:"flex",flexDirection:"row-reverse",justifyContent:"space-around",padding:20}}>
 <Button 
 onClick={()=>{
   setSearchParams((old)=>{
     old.set("page","details")
     return old
   })
 }}
 
 color={page==="details"?"success":"info"} variant="contained">معلومات الاختبار</Button>

 {[0,1,2,3,4,5,6,7,8,9].map(item=>{
   return <Button onClick={()=>{
     setSearchParams((old)=>{
       old.set("page",item)
       return old
     })
   }} color={page==item?"success":"info"}  variant="contained">{item+1}</Button>
 })}

<Button onClick={()=>{
    setSearchParams((old)=>{
     old.set("page","audience")
     return old
   })
 }} 
 
 color={page==="audience"?"success":"info"} 
 variant="contained">الجمهور المستهدف</Button>

 <Button onClick={()=>{
    setSearchParams((old)=>{
     old.set("page","tags")
     return old
   })
 }} 
 
 color={page==="tags"?"success":"info"} 
 variant="contained">إضافة الوسوم</Button>

<Button onClick={()=>{
    setSearchParams((old)=>{
     old.set("page","complete")
     return old
   })
 }} 
 
 color={page==="complete"?"success":"info"} 
 variant="contained">إتمام الإختبار</Button>
</div>
}

export default SwitchPage