import { useEffect ,useState } from "react"
import { getTeacherNotification } from "../API/notification"

const TeacherNotificationPage=()=>{
    const [notifications,setNotifications]=useState([])
    
   const loadNotification=async()=>{
     try{
        const data=await getTeacherNotification("all")
        setNotifications(data)
     }catch(err){

     }
   }
    useEffect(()=>{
     loadNotification();     
    },[])
  return <>
   <table style={{width:"100%",margin:5}}>
    <tr>
        <th>الإشعار</th>
        <th>التاريخ</th>
        <th>مقروء؟</th>
    </tr>
    {notifications && notifications.map(item=>{
       return <tr style={{backgroundColor:item.has_read===0?"rgb(200,100,100)":"rgb(100,200,150)"}}>
        <td>{item.content}</td>
        <td>{item.created_at}</td>
        <td>{item.has_read===0?"❌":"✅"}</td>
       </tr>
    })}
   </table>
  </>
}
export default TeacherNotificationPage