import Wallpaper1 from "../images/wallpapers/wallpaper1.jpg"
import Wallpaper2 from "../images/wallpapers/wallpaper2.jpg"
import Wallpaper3 from "../images/wallpapers/wallpaper3.jpg"
import Wallpaper4 from "../images/wallpapers/wallpaper4.jpg"
import Wallpaper5 from "../images/wallpapers/wallpaper5.jpg"
import Wallpaper6 from "../images/wallpapers/wallpaper6.jpeg"
import Wallpaper7 from "../images/wallpapers/wallpaper7.jpg"
import Wallpaper8 from "../images/wallpapers/wallpaper8.jpg"
import Wallpaper9 from "../images/wallpapers/wallpaper9.jpg"
import Wallpaper10 from "../images/wallpapers/wallpaper10.jpg"
import Wallpaper11 from "../images/wallpapers/wallpaper11.jpg"

export const wallpapers=[
    Wallpaper1,Wallpaper2,Wallpaper3,
    Wallpaper4,Wallpaper5,Wallpaper6,
    Wallpaper7,Wallpaper8,Wallpaper9,
    Wallpaper10,Wallpaper11
]

export  const isValidImg=(url)=>{
    return url?url.indexOf("https://i.ibb.co/")==0:false
   }