
import { useSearchParams } from "react-router-dom"
import { useState,useEffect } from "react";
import { generateReportAPI } from "../API/submit";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReplayIcon from '@mui/icons-material/Replay';
import Paper from '@mui/material/Paper';
import {Box,Stack} from "@mui/material";
import { useLocation } from "react-router-dom";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import { like,dislike } from '../API/quiz';
import { loadLikes } from "../API/quiz";
import ShareModal from "../components/ShareModal";
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import { getFavorite } from "../API/favorite";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { addToFavorite,removeFromFavorite } from '../API/favorite';
import toast from "react-hot-toast";
import { addToComplete } from "../API/complete";
function Question({submited,correct,answer,question}){
    const [open, setOpen] = useState(false);
    const [params]=useSearchParams();
   
       if(question){
        
    return <>

   
    <Box sx={{m:1,p:1,border:"1.5px solid #00A2E8"}}>
        
    { question.imageURI && <img style={{width:"100%"}} src={question.imageURI} />}
    <Box sx={{border:"1px dashed black",p:1,fontSize:25}}>{  question.statment}</Box>
    <Stack spacing={1} sx={{m:1}}>
    {question.options&&question.options.map((item,inx)=>{
        console.log(item)
        return <Button 

       
        sx={{fontFamily:"cocon-next-arabic",fontSize:25,border:item.index===question.answer?"2px solid yellow":""}}
        
        color={item.index==question.correct?"success":"error"} 

        variant={"contained"}
        
        
        >{item.data}</Button>
    })}
    </Stack>

</Box></>
       }
      return <h1>No question</h1>
  
}
const ReportPage=({user})=>{
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
        width,
        height
        };
    }
 const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());  
const [params]=useSearchParams();
const [questions,setQuestions]=useState([])
const [result,setResult]=useState("")
const [index,setIndex]=useState(0)
const [open,setOpen]=useState(false)
const navigator=useNavigate()
const location = useLocation();
const [isFavorite,setIsFavorite]=useState(false)
const [likes,setLikes]=useState({
    likes_count:0,
    dislikes_count:0,
    liked:false,
    disliked:false
})
const  questionsList = location?.state?.questions || [];





let session_id=params.get("session");
let id=params.get("id");
const generateReport=async()=>{
    try{
     const report=await generateReportAPI(id,session_id);
     setResult(report.result)
     setQuestions(report.submissions)
    }catch(err){

    }
}

const loadFavorit=async()=>{
    try{
     const data=await getFavorite(id);
     setIsFavorite(data.favorite==1);
    }catch(err){

    }
}
const getLikes=async()=>{
  try{
  const data=await loadLikes(id)
  setLikes(data);
  }catch(err){

  }
}
useEffect(()=>{
    getLikes();
    generateReport()
    loadFavorit();
    addToComplete(id)
    function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
},[]);

return <>
<h1> {result} : النتيجة </h1>
<ShareModal quiz_id={id} {...{open,setOpen}}/>
<Paper 
className="paper-class"
sx={{width:"60%",minWidth:(windowDimensions.width-40),margin:"auto",padding:"20px"

}} elevation={2}>
    <div >
    <div
    style={{
        display:"flex",alignItems:"flex-start",flexWrap:"wrap"
,justifyContent:"center",gap:10,flexDirection:"row-reverse",
minHeight:"fit-content"
    }}
    
    >
{questions && questions.map((item,inx)=>{
        return <Button sx={{border:inx==index?("4px solid red"):""}} 
         onClick={()=>{setIndex(inx)}} 
          variant="contained" color={item.correct==1?"success":"error"}> {inx+1}  السؤال </Button>
    })}
    
    </div>
    <div>
    <Question question={questionsList[index]} />
 
    </div>
    </div>
</Paper>
<Paper sx={{p:2,m:1}} elevation={2}>
<div style={{display:"flex",alignItems:"center",justifyContent:"center" ,gap:10}}>
<IconButton onClick={()=>{
          
          setOpen(true)
        }
        }  aria-label="share">
          <ShareIcon />
        </IconButton>
   
   <ThumbUpIcon onClick={async()=>{
     try{
      const data=await like(id);
      setLikes(data);
     }catch(err){

     }
   }} className="like-icon" style={{color:likes?.liked?"rgb(20,150,200)":"rgb(200,200,200)"}} />
   <span> {likes?.likes_count} </span>
   <ThumbDownIcon
   onClick={async()=>{
    try{
     const data=await dislike(id);
     setLikes(data);
    }catch(err){

    }
  }}
   
   className="like-icon" style={{color:likes?.disliked?"rgb(20,150,200)":"rgb(200,200,200)"}}/>
<span> {likes?.dislikes_count}</span>

{isFavorite && <FavoriteIcon
     onClick={async()=>{
      try{
         await removeFromFavorite(id)
         toast.success("تم إزالة الإختبار من قائمة المفضلة")
        setIsFavorite(false)
      }catch(err){
           toast.error("حدث خطأ أثناء إزالة الإختبار من المفضلة")
      }
   }}
     className="fav-icon"
     />}
{!isFavorite && <FavoriteBorderIcon onClick={async()=>{
        try{
           await addToFavorite(id)
           toast.success("تم إضافة الإختبار لقائمة المفضلة")
          setIsFavorite(true)
        }catch(err){
             toast.error("حدث خطأ أثناء إضافة الإختبار للمفضلة")
        }
     }} className="fav-icon"/>}
</div>

<div style={{display:"flex",alignItems:"center",justifyContent:"center" ,gap:10}}>

<Button 
    variant="contained"
    onClick={
        ()=>{
      navigator("/quiz/"+id+"?s=repeat")
        }
    }>
<ReplayIcon />
</Button>
    <h1>إعادة الاختبار</h1>
</div>
</Paper>
</>
}

export default ReportPage