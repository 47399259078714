import { useState } from "react"
import AddQuestion from "../components/AddQuestion"
import { Button } from "@mui/material"
import { Box } from "@mui/material"
import {Stack} from "@mui/material"
import DeleteForever from "@mui/icons-material/DeleteForever"
import CreateIcon from '@mui/icons-material/Create';
import {TextField} from "@mui/material"
import ArabicTextField from "../components/ArabicTextField"
import { addQuiz } from "../API/quiz"
import {Modal,Input,InputAdornment,FormControl,Select,InputLabel,MenuItem} from "@mui/material"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

function Question({question,questions,setQuestions,isEnglish}){
    return <Box sx={{m:1,p:1,border:"1.5px solid #00A2E8"}}>
        {question.imageURI && <img style={{width:"100%"}} src={question.imageURI} />}
        <Box sx={{border:"1px dashed black",p:1}}>{question.statment}</Box>
        <Stack spacing={1} sx={{m:1}}>
        {question.options.map((item,inx)=>{
            return <Button 
            sx={{fontFamily:"cocon-next-arabic"}}
            
            color={question.correct==inx?"success":"primary"} variant="contained">{item}</Button>
        })}
        </Stack>
        <DeleteForever  onClick={()=>{
              setQuestions(questions.filter(item=>item!=question))
        }} style={{cursor:"pointer"}}/>
        <CreateIcon  style={{cursor:"pointer"}}/>
    </Box>
}

function TagComp({tag,setTags}){
  return <div style={{padding:"5px",  backgroundColor:"lightgreen",borderRadius:"10px",display:"flex",alignItems:"center",justifyContent:"center"}}>
    {tag} <DeleteForever className="delete-icon" onClick={()=>{
      setTags((old_tags)=>{
        return old_tags.filter(item=>item!=tag)
      })
    }}/>
  </div>
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:5,
    flexDirection:"column"
  };
  

function ArabicQuiz(){
  const [questions,setQuestions]=useState([])
  const [newQuestion,setNewQuestion]=useState({
   statment:"",
   options:["",""],
   correct:1,
   imageURI:""
 })
 const navigator=useNavigate()
 const [quiz_id,setQuizId]=useState("")
 const [open,setOpen]=useState(false)
 const [title,setTitle]=useState("")
 const [description,setDescription]=useState("")
 const [imageThumbnail,setImageThumbnail]=useState("")

 const [grade,setGrade]=useState(0)
 const [level,setLevel]=useState(0)
 const [tags,setTags]=useState([])
 const [tag,setTag]=useState("")
 const isValidImg=(url)=>{
  return url.indexOf("https://i.ibb.co/")==0
 }
 const handleChange = (event) => {
   setGrade(event.target.value);
 };
   return <>
   <Modal
 open={open}
 onClose={()=>{setOpen(false)}}
 aria-labelledby="modal-modal-title"
 aria-describedby="modal-modal-description"
>
 <Box sx={style}>

   <Box sx={{ minWidth: 120,display:"flex",flexDirection:"column",gap:1, alignItems:"center",
     justifyContent:"center"
    }}>
     {false&<FormControl fullWidth>
       <InputLabel id="demo-simple-select-label">  المرحلة الدراسية</InputLabel>
       <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={grade}
         onChange={handleChange}
       >
         <MenuItem value={0}>أول ابتدائي</MenuItem>
         <MenuItem value={1}>ثاني ابتدائي</MenuItem>
         <MenuItem value={2}>ثالث ابتدائي</MenuItem>
         <MenuItem value={3}>رابع ابتدائي</MenuItem>
         <MenuItem value={4}>خامس ابتدائي</MenuItem>
         <MenuItem value={5}>سادس ابتدائي</MenuItem>
         <MenuItem value={6}>سابع اعدادي</MenuItem>
         <MenuItem value={7}>ثامن اعدادي</MenuItem>
         <MenuItem value={8}>تاسع اعدادي</MenuItem>
         <MenuItem value={9}>عاشر ثانوي</MenuItem>
         <MenuItem value={10}>حادي عشر ثانوي</MenuItem>
         <MenuItem value={11}>ثاني عشر ثانوي</MenuItem>
        

       </Select>
     </FormControl>}

     {false&<FormControl fullWidth>
       <InputLabel id="demo-simple-select-label"> مستوى الصعوبة</InputLabel>
       <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={level}
         onChange={(e)=>{
           setLevel(e.target.value)
         }}
       >
         <MenuItem value={0}>سهل</MenuItem>
         <MenuItem value={1}>متوسط</MenuItem>
         <MenuItem value={2}>صعب</MenuItem>
       </Select>
     </FormControl>}
   

     <FormControl variant="standard">
       <InputLabel htmlFor="input-with-icon-adornment">
         إضافة وسوم (تاغ)
       </InputLabel>
       <Input
        value={tag}
        onChange={(e)=>{
            setTag(e.target.value)
        }}
         id="input-with-icon-adornment"
         startAdornment={
           <InputAdornment position="start">
             < AddCircleRoundedIcon className="add-icon" onClick={()=>{
              if(tag.trim()==""){
                toast.error("لا يمكن اضافة وسم فارغ")
                return
              }
              if(tags.length==10){
                toast.error("لا يمكن اضافة أكثر من عشرة وسوم")
                return
              }
              if(tags.find((item)=>item.trim().toLowerCase()==tag.trim().toLowerCase())){
                toast.error("وسم مكرر")
                return
              }
               setTags([...tags,tag])
               setTag("")
             }}/>
           </InputAdornment>
         }
       />
     </FormControl>
       {tags && tags.map(tag=><TagComp tag={tag} setTags={setTags}/>)}

       <Box>
        <InputLabel>
        هذا الإختبار يحقق شروط منصة إختبار لإضافة الاختبارات
        </InputLabel>

        
       </Box>
   </Box>
   
 
   <Button onClick={async()=>{
           if(questions.length<10){
            toast.error("يجب أن يكون الإختبار عشرة أسئلة على الأقل")
            return
           }
           if(tags.length<5){
            toast.error("يجب إضافة خمسة وسوم على الأقل")
            return
           }
            const id=await addQuiz(questions,tags,title,description,isValidImg(imageThumbnail)?imageThumbnail:"")
            setQuizId(id)
            navigator("/quiz/"+id)
            
   }} variant="contained" color="success">
     
     إنشاء الأختبار 
   </Button>

   
 </Box>
</Modal>
   <ArabicTextField value={title} style={{fontSize:25}} onChange={(e)=>{
     setTitle(e.target.value)
     
   }} label={"عنوان الإختبار"} placeholder={"أدخل عنوان الاختبار"} />

<ArabicTextField style={{fontSize:25}} value={description} onChange={(e)=>{
     setDescription(e.target.value)
   }} label={"وصف الإختبار"} placeholder={"أدخل وصف الاختبار"} />
   <ArabicTextField style={{fontSize:25}} value={imageThumbnail} onChange={(e)=>{
     setImageThumbnail(e.target.value)
   }} label={"صورة الاختبار"} placeholder={"أدخل صورة الاختبار"} />

<ArabicTextField value={title} style={{fontSize:25}} onChange={(e)=>{
     setTitle(e.target.value)
     
   }} label={"أدخل أيقونة الإختبار"} placeholder={"أدخل أيقونة الاختبار"} />

   {imageThumbnail && imageThumbnail !="" &&(isValidImg(imageThumbnail)&& <img src={imageThumbnail} style={{width:"400px"}} />)}
{imageThumbnail && imageThumbnail !="" &&(!isValidImg(imageThumbnail))&&<h1>Invalid image source</h1>}
   
   
   <Stack   direction={"column"} alignItems={"center"}>
    <Box sx={{
       display:"flex",
    alignItems:"center",justifyContent:"center",
    flexDirection:"column",  width:"100%",
    background:"rgb(120,255,100,0.02)",
    p:4
    }}>
   <Box sx={{maxWidth:400,background:"rgba(0,0,0,0.1)"}}>
   {questions.map((item,index)=>{
       return <div style={{margin:50,border:"2px solid blue"}}> 
       <div>{index>0&&<Button onClick={()=>{
        setQuestions((old_questions)=>{
          let new_questions=[...old_questions]
          let temp=new_questions[index-1]
          new_questions[index-1]=new_questions[index]
          new_questions[index]=temp
          setQuestions(new_questions)
        })
       }}>Move up</Button>}</div>
        <Question question={item} questions={questions} setQuestions={setQuestions}/>
     <div> {index<questions.length-1&&<Button
     onClick={()=>{
      setQuestions((old_questions)=>{
        let new_questions=[...old_questions]
        let temp=new_questions[index+1]
        new_questions[index+1]=new_questions[index]
        new_questions[index]=temp
        setQuestions(new_questions)
      })
     }}
     
     >Move down</Button>}</div>
       </div>
        })}
   </Box>
   </Box>
   <AddQuestion newQuestion={newQuestion} setNewQuestion={setNewQuestion}/>

    <Button
    
     sx={{fontFamily:"cocon-next-arabic"}}
    onClick={()=>{
       let added={...newQuestion,options:[...newQuestion.options]}
       setQuestions([...questions,added])
    }} variant="contained">اضافة السؤال</Button>

    
    </Stack>
    <Button 
    sx={{m:2}}
    variant="contained"
    color="success"
    onClick={()=>{

      setOpen(true)
    }}>

       إنشاء الإختبار
    </Button>
   </>

}

function EnglishQuiz(){
  const [questions,setQuestions]=useState([])
  const [newQuestion,setNewQuestion]=useState({
   statment:"",
   options:["",""],
   correct:1,
   imageURI:""
 })
 const navigator=useNavigate()
 const [quiz_id,setQuizId]=useState("")
 const [open,setOpen]=useState(false)
 const [title,setTitle]=useState("")
 const [description,setDescription]=useState("")
 const [imageThumbnail,setImageThumbnail]=useState("")

 const [grade,setGrade]=useState(0)
 const [level,setLevel]=useState(0)
 const [tags,setTags]=useState([])
 const [tag,setTag]=useState("")
 const isValidImg=(url)=>{
  return url.indexOf("https://i.ibb.co/")==0
 }
 const handleChange = (event) => {
   setGrade(event.target.value);
 };
   return <>
   <Modal
 open={open}
 onClose={()=>{setOpen(false)}}
 aria-labelledby="modal-modal-title"
 aria-describedby="modal-modal-description"
>
 <Box sx={style}>

   <Box sx={{ minWidth: 120,display:"flex",flexDirection:"column",gap:1, alignItems:"center",
     justifyContent:"center"
    }}>
     
   

     <FormControl variant="standard">
       <InputLabel htmlFor="input-with-icon-adornment">
         إضافة وسوم (تاغ)
       </InputLabel>
       <Input
        value={tag}
        onChange={(e)=>{
            setTag(e.target.value)
        }}
         id="input-with-icon-adornment"
         startAdornment={
           <InputAdornment position="start">
             < AddCircleRoundedIcon className="add-icon" onClick={()=>{
              if(tag.trim()==""){
                toast.error("لا يمكن اضافة وسم فارغ")
                return
              }
              if(tags.length==10){
                toast.error("لا يمكن اضافة أكثر من عشرة وسوم")
                return
              }
              if(tags.find((item)=>item.trim().toLowerCase()==tag.trim().toLowerCase())){
                toast.error("وسم مكرر")
                return
              }
               setTags([...tags,tag])
               setTag("")
             }}/>
           </InputAdornment>
         }
       />
     </FormControl>
       {tags && tags.map(tag=><TagComp tag={tag} setTags={setTags}/>)}
   </Box>
   
 
   <Button onClick={async()=>{
  if(questions.length<10){
    toast.error("يجب أن يكون الإختبار عشرة أسئلة على الأقل")
    return
   }
   if(tags.length<5){
    toast.error("يجب إضافة خمسة وسوم على الأقل")
    return
   }
            const id=await addQuiz(questions,tags,title,description,isValidImg(imageThumbnail)?imageThumbnail:"")
            setQuizId(id)
            navigator("/quiz/"+id)
            
   }} variant="contained" color="success">
     
     Create quiz
   </Button>

   
 </Box>
</Modal>
   <TextField value={title} style={{fontSize:25}} onChange={(e)=>{
     setTitle(e.target.value)
     
   }}
   
 
   variant="filled"

   sx={{width:"100%"}}
   multiline
   
   label={"Title"} placeholder={"Enter title"} />

<TextField 
variant="filled"
sx={{width:"100%"}}
multiline
style={{fontSize:25}} value={description} onChange={(e)=>{
     setDescription(e.target.value)
   }} label={"Description"} placeholder={"Enter the description"} />

<TextField 
variant="filled"
sx={{width:"100%"}}
multiline
style={{fontSize:25}} value={imageThumbnail} onChange={(e)=>{
     setImageThumbnail(e.target.value)
   }} label={"Image thumbnail"} placeholder={"Enter the quiz image"} />
{imageThumbnail && imageThumbnail !="" &&(isValidImg(imageThumbnail)&& <img src={imageThumbnail} style={{width:"400px"}} />)}
{imageThumbnail && imageThumbnail !="" &&(!isValidImg(imageThumbnail))&&<h1>Invalid image source</h1>}
   <Stack   direction={"column"} alignItems={"center"}>
    <Box sx={{
       display:"flex",
    alignItems:"center",justifyContent:"center",
    flexDirection:"column",  width:"100%",
    background:"rgb(120,255,100,0.02)",
    p:4
    }}>
   <Box sx={{maxWidth:400,background:"rgba(0,0,0,0.1)"}}>
   {questions.map((item,index)=>{
       return <div style={{margin:50,border:"2px solid blue"}}>
        <div>{index>0&&<Button onClick={()=>{
        setQuestions((old_questions)=>{
          let new_questions=[...old_questions]
          let temp=new_questions[index-1]
          new_questions[index-1]=new_questions[index]
          new_questions[index]=temp
          setQuestions(new_questions)
        })
       }}>Move up</Button>}</div>
       <Question isEnglish question={item} questions={questions} setQuestions={setQuestions}/>
       <div> {index<questions.length-1&&<Button
     onClick={()=>{
      setQuestions((old_questions)=>{
        let new_questions=[...old_questions]
        let temp=new_questions[index+1]
        new_questions[index+1]=new_questions[index]
        new_questions[index]=temp
        setQuestions(new_questions)
      })
     }}
     
     >Move down</Button>}</div>
       </div>
   })}
   </Box>
   </Box>
   <AddQuestion isEnglish newQuestion={newQuestion} setNewQuestion={setNewQuestion}/>

    <Button
    
     sx={{fontFamily:"cocon-next-arabic"}}
    onClick={()=>{
       let added={...newQuestion,options:[...newQuestion.options]}
       setQuestions([...questions,added])
    }} variant="contained">Add Question</Button>

    
    </Stack>
    <Button 
    sx={{m:2}}
    variant="contained"
    color="success"
    onClick={()=>{

      setOpen(true)
    }}>

       Create quiz
    </Button>
   </>

}

export default function GenerateQuiz(){
  const [language,setLanguage]=useState("ar")
   return <>
   لغة الاختبار 
   <div>
     <Button variant="contained" color={language=="ar"?"success":"info"}  onClick={()=>setLanguage("ar")}>عربي</Button>
     <Button variant="contained" color={language=="en"?"success":"info"}  onClick={()=>setLanguage("en")}>إنكليزي</Button>
   </div>
   {language=="ar"&&<ArabicQuiz/>}
   {language=="en"&&<EnglishQuiz/>}
   </> 
}