import { useState } from "react";
import RenderQuestionPage from "./RenderQuestionPage";
import RenderDetailsPage from "./RenderDetailsPage"
import RenderTagsPage from "./RenderTagsPage"
import RenderCompletePage from "./RenderCompletePage"
import RenderAudiencePage from "./RenderAudiencePage";

function RenderPage({page,quiz,setQuiz,setSearchParams}){
    const [tag,setTag]=useState("");
    
    if(page==="details"){
     return <RenderDetailsPage setQuiz={setQuiz} quiz={quiz} />
    }else if(page==="tags"){
     return <RenderTagsPage setQuiz={setQuiz} quiz={quiz} tag={tag} setTag={setTag} />
    } else if(page==="complete"){
     return <RenderCompletePage  setQuiz={setQuiz}  quiz={quiz} setSearchParams={setSearchParams} />
    }else if(page==="audience"){
       return <RenderAudiencePage  quiz={quiz} setQuiz={setQuiz} />
    } else return <RenderQuestionPage quiz={quiz}  setQuiz={setQuiz} page={page}/>
  }
  
  export default RenderPage