import { useState,useEffect } from "react"
import { loadAllQuizes } from "@api/quiz";
import { toggleQuiz,deleteQuiz } from "@api/quiz";

function QuizPage(){
    const [quizes,setQuizes]=useState([])
    const loadQuizes=async()=>{
      try{
         const arr=await loadAllQuizes();
         setQuizes(arr);
         
      }catch(err){
  
      }
  
    }
    useEffect(()=>{
      loadQuizes()
    },[])
  
    return <>
       {quizes && quizes.map(item=>{
        return <div style={{border:"2px solid black",margin:10}}>
   <h1>{item.title}</h1>
  <div>{item.description}</div>
  <div><span>{item.views}</span></div>
  {item.is_active==1 &&<div><button onClick={()=>{
    toggleQuiz(item.id)
    setQuizes((old)=>{
      let new_items=[...old]
      new_items.find(el=>el.id==item.id).is_active=0
      return new_items
    })
  }}>إلغاء تفعيل</button></div>}
  {item.is_active==0 &&<div><button onClick={()=>{
    toggleQuiz(item.id)
    setQuizes((old)=>{
      let new_items=[...old]
      new_items.find(el=>el.id==item.id).is_active=1
      return new_items
    })
  }}>تفعيل</button></div>}
  <div><button onClick={()=>{
    deleteQuiz(item.id)
    setQuizes((old)=>{
      return old.filter(el=>el.id !=item.id)
    })
  }}>حذف</button></div>
        </div>
       })}
    </>
  }

  export default QuizPage;