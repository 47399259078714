import { useEffect ,useState } from "react"
import toast from "react-hot-toast"
import { readNotification} from "../API/notification"
import { Howl } from 'howler';
import { icons } from "../util/icons";

const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"


const TeacherNotification=()=>{
 
   
   async function showNotification(item){

  
      const id = await toast(
          (t) => (
            <span>
              {icons.find(el=>el.id===item.icon).icon} {item.content}
              <button
                onClick={() => {
                  toast.dismiss(t.id)
                     playSoundDimiss();
                      readNotification(item.id)
                }}
                style={{
                  marginLeft: '10px',
                  backgroundColor: 'lightblue',
                  color: 'black',
                  border: 'none',
                  padding: '8px 12px',
                  cursor: 'pointer',
                  margin:"10px"
                }}
              >
                تم
              </button>
            </span>
          ),
          {
            duration: Infinity,
            position: 'top-right',
            style: {
              border: '1px solid blue',
              padding: '16px',
              color: 'black',
              backgroundColor: '#ffffff',
              textAlign:"right",
              direction:"rtl"
            },
          }
        );
   
    
    let notifications=window.localStorage.getItem("notificationList");
    if(!notifications){
      notifications={list:[]}
    }else{
      notifications=JSON.parse(notifications);
      
    }
    notifications.list.push({id,notification_id:item.id})
    window.localStorage.setItem("notificationList",JSON.stringify(notifications))
 
   }


    const playSoundNotification = () => {
        const sound = new Howl({
          src: ['/media/notification.mp4'],
        });
    
        sound.play();
      };
      const playSoundDimiss = () => {
        const sound = new Howl({
          src: ['/media/dismiss.wav'],
        });
    
        sound.play();
      };


 useEffect(() => {
  try{
    let token=localStorage.getItem("usertoken")
    
  const eventSource = new EventSource(serverURL+"/notification/teacher/all?token="+token);
   
  eventSource.onmessage = function(event) {
 // playSoundNotification();
  
   const newNotification = JSON.parse(event.data);
   
    if(newNotification.action==="add"){
    showNotification(newNotification);
    }else if(newNotification.action==="remove"){
      let notifications=window.localStorage.getItem("notificationList")

      if(!notifications){
        notifications="{list:[]}"
      }
      notifications=JSON.parse(notifications)
      let list=notifications.list

      
      let item= list.find(item=>item.notification_id===newNotification.id);
      console.log(item)
      console.log("---------------")
      toast.dismiss(item.id)
      list=list.filter(item=>item.notification_id!==newNotification.id)
      window.localStorage.setItem("notificationList",JSON.stringify({list}))
    }else{
      ///console.log(newNotification);
    }
    //setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
  };

  eventSource.onerror = function(err) {
    console.error('EventSource failed:', err);
    eventSource.close();
  };
   return () => {
    eventSource.close();
  };
}catch(err){
  console.log(err);
}

 
}, []);


  return <>
  </>
}

export default TeacherNotification