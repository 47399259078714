import { useState,useEffect } from "react"

function ContactUsPage(){
    const [messages,setMessages]=useState([])
    
    const loadMessages=async()=>{
  
    }
    useEffect(()=>{
      loadMessages();
    },[])
  
    return <>
     {messages && messages.map(item=>{
      return <div>
        Test
      </div>
     })}
    
    </>
  }
  export default ContactUsPage