import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"



export const downloadDatabase=async()=>{

    try {
        const response = await axios({
            url: serverURL+"/database",
            method: 'GET',
            responseType: 'blob', // Important for file download
            headers:{ Authorization:localStorage.getItem("token")

            }
        });

        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'database.db'); // Set the file name
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Download error:', error);
    }
   }

export const uploadDatabase=async(file)=>{

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(serverURL+"/database", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                   ,
                            Authorization:localStorage.getItem("token")
                      
                },
            });
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
       
    
}

export const loadBackUpDirectoryFiles=async()=>{
    try{
       const {data}=await axios.get(serverURL+"/database/list",{
        headers:{
            Authorization:localStorage.getItem("token")
        }
    });

       return data
    }catch(err){
     console.log(err);
    } 
}

export const deleteFile=async(filename)=>{
    try{
    await axios.delete(serverURL+"/database/"+filename,{
        headers:{
            Authorization:localStorage.getItem("token")
        }
    });


    }catch(err){
        console.log(err);
    }
}

export const moveToProduction=async(filename)=>{
    try{

        await axios.post(serverURL+"/database/movetoproduction/"+filename,{},{
            headers:{
                Authorization:localStorage.getItem("token")
            }
        })
    }catch(err){
        console.log(err);
    }
}