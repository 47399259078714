import { get_teacher_account_by_id} from "../API/teacher_account"
import { useState,useEffect } from "react"
import {useParams} from "react-router-dom"
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Button } from "@mui/material";
import CardComp from "../components/CardComp";
const TeacherPage=()=>{
    const [state,setState]=useState({
        publicName:"",
        publicPhoto:"",
        publicBio:"",
        totalViews:0,
        totalLikes:0,
        totalQuizes:0,
        quizes:[]
    })
    const {id}=useParams();
    const load_teacher=async()=>{
      try{
        const {public_name,bio,image_url,views_count,likes_count,quizes} = await get_teacher_account_by_id(id);
        setState({
            ...state,
            publicName:public_name,
            publicBio:bio,
            publicPhoto:image_url,
            totalViews:views_count,
            totalLikes:likes_count,
            quizes
        })
      }catch(err){

      }
    }

    useEffect(()=>{
           load_teacher();
    },[])

  return <div style={{height:"fit-content",minHeight:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>
      <div style={{margin:15}}>
          <div>{<img style={{borderRadius:10,border:"1px solid black"}} width={64} height={64} src={state.publicPhoto} />}</div>
        <div>{state.publicName}</div>
      </div>
      <div>{state.publicBio}</div>
      <div style={{fontSize:20,display:"flex",alignItems:"center",justifyContent:"center",gap:10}}>
         <span>{state.totalViews} </span><VisibilityIcon fontSize="20" color={"info"}/> 
         <span>{state.totalLikes}</span> <ThumbUpIcon  fontSize="20" color={"info"}/>
          <Button variant="contained">متابعة</Button>
      </div>
      <div style={{display:"flex",overflow:"scroll",alignItems:"start",justifyContent:"start",flexWrap:"wrap",  width:"100%",flex:1 ,borderRadius:5,margin:5,border:"1px solid #1976D2",marginTop:10}}>
        {state.quizes.map(quiz=>{
          return <CardComp user={{}} setQuiz={()=>{}} distanation={""} setSQuiz={()=>{}} setOpen={()=>{}} quiz={quiz}/>
        })}
        
        
      </div>
  </div>
}
export default TeacherPage