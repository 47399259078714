import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useState ,useEffect} from 'react';
import { search } from '../API/quiz';
import { getResults } from '../API/quiz';
import CardComp from '../components/CardComp';
import { loadReleventQuizes } from '../API/quiz';
import ShareModal from '../components/ShareModal';
import { Button } from '@mui/material';
import { like,dislike } from '../API/quiz';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {Paper} from '@mui/material';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import SearchFilter from '../components/SearchFilter';
import MenuIcon from '@mui/icons-material/Menu';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.45),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.65),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    direction:"rtl",
    fontFamily:"cocon-next-arabic",
    fontWeight:500,
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),

      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        }
      },
    },
  }));
const theme = createTheme({
    direction: 'rtl', // Specify RTL direction
  });
 function QuestionThumbnail({user,quiz,setQuiz,setOpen,setSQuiz,distanation}){
    return <CardComp user={user} setQuiz={setQuiz} distanation={distanation} setSQuiz={setSQuiz} setOpen={setOpen} quiz={quiz}/>
    
 }


export default function Home({user}){
     const [results,setResults]=useState([])
    const [term,setTerm]=useState("")
    const [open,setOpen]=useState(false)
    const [s_quiz,setSQuiz]=useState("")
  
    const [count,setCount]=useState(0)



    
    const loadResults=async(list)=>{

      let data=await getResults(list)
      data=data.map(item=>({...item,search_result:true})) 
      console.log(data);
      setResults(data)
    }
    const loadRelevant=async()=>{
        try{
          
          const data=await loadReleventQuizes(results.filter(item=>!item.search_result).length);
          console.log(data)
        if(data){
        setResults((old_results)=>{
          old_results=old_results.filter(item=>!item.search_result);
          return [...old_results,...data]
        });
      }
        }catch(err){
         console.log(err);
        }
    }
    useEffect(()=>{
   
      loadRelevant(0);
    },[])
    const [expanded,setExpanded]=useState(false)
    const translateX = expanded ? 'max(-500px, -100vw)' : '0px';

    return   <div className="home-page">
    <ShareModal quiz_id={s_quiz} {...{open,setOpen}}/>
    
    <div style={{height:"100%", width:"100%",display:"flex",alignItems:"center",justifyContent:"start",flexDirection:"column"}}>
    <div style={{width:"100%",flex:1}}>
    <ThemeProvider  theme={theme}>
    <Box sx={{ flexGrow: 2,background:"grey" }}>
        <Toolbar sx={{justifyContent:"center"}}>

          <Search>
            <SearchIconWrapper>
              <SearchIcon onClick={async()=>{
                 
                    if(term.trim()==""){
                        return loadRelevant();
                    }
                    const list= await search(term)
                  loadResults(list)
                
              }}/>
            </SearchIconWrapper>
            <StyledInputBase
            onKeyDown={async(e)=>{
                if(e.key=="Enter"){
                    if(term.trim()==""){
                        return loadRelevant();
                    }
                    const list= await search(term)
                  loadResults(list)
                }
            }}
            onChange={(e)=>{
                setTerm(e.target.value)
            }}
              placeholder="مربع البحث"
              inputProps={{ 'aria-label': 'search' }}
            />
           
          </Search>
           <Button color={expanded?"warning":"info"} sx={{ml:1}} variant='contained' onClick={()=>{
      setExpanded(!expanded)
    }}><MenuIcon/></Button>
        </Toolbar>
       
    </Box>
    <div style={{width:"100%",overflowX:"hidden"}}>
    <Box className="home-container" sx={{display:"flex",transition:"1s",height:"100%",transform: `translate(${translateX}, 0px)` }}>
    
    <ResponsiveMasonry
      style={{ flex: "0 0 100vw",height:"100%"}}
                columnsCountBreakPoints={{300:1,550: 2, 750: 2, 900: 3, 1200:4}}
            >
           <Masonry>
         {results && results.length>0 && results.map && (typeof results.map=="function") && results.map(item=>{
        return <QuestionThumbnail user={user} setQuiz={setResults}  distanation={"quiz"} setSQuiz={setSQuiz} setOpen={setOpen} quiz={item} />
      
    })}
   
    {results && results.length==0 && <div> لا يوجد نتائج بحث</div>}
       </Masonry>
       </ResponsiveMasonry>

      <Box sx={{flex: "0 0 min(500px, 100vw)"}} className={"filter-search-box"} >
        <Paper elevation={5} sx={{height:"100%",m:0,backgroundColor:"rgb(180,220,255)"}}>
           <SearchFilter />

        </Paper>
      
      </Box>  
    </Box>
    </div>
  {results && results.length>0 && <Button style={{margin:10}} variant='contained' onClick={()=>{
    loadRelevant()
  }}>عرض المزيد</Button>}

    </ThemeProvider>
    </div>
    
     </div>
     </div>
}