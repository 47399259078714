import axios from "axios"

const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"




export const addToComplete=async(quiz_id)=>{
    try{
        await axios.post(serverURL+"/complete/",{quiz_id},{
            headers:{
                Authorization:window.localStorage.getItem("usertoken")
             } ,
             withCredentials:true
        })
    }catch(err){

    }
}

export const getComplete=async(quiz_id)=>{
    try{ 
        const {data}=await axios.get(serverURL+"/complete/"+quiz_id,{
            headers:{
                Authorization:window.localStorage.getItem("usertoken")
             } 
        })
        return data

    }catch(err){
        console.log(err)
        return []
    }
}
