import React, { useState } from 'react';

import { Button,Container,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import {useSearchParams} from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
const SWITCHSIGNUP="إنشاء حساب جديد"
const SWITCHSIGNIN="تسجيل الدخول لحسابي"
const SIGNIN="تسجيل الدخول"
const SIGNUP="إنشاء حساب"
const AuthComponent = () => {
  const classes = useStyles();
  const [queries,setQueries]=useSearchParams();
  const location=useLocation();
  let id=queries.get("id")
  let session=queries.get("session")
  let questions=location?.state?.questions
  const isSignIn=queries.get("state") !=2
  const navigator=useNavigate();
  const toggleAuthMode = () => {
  
   queries.set("state",isSignIn?2:1)
   setQueries(queries,{state:{questions}})
  };
  const redirect=()=>{
    if(!session){
      navigator("/")
    }else{
      navigator("/report?id="+id+"&session="+session,
      { state: { questions }}
      );
    }
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h4" gutterBottom>
        {isSignIn?SIGNIN : SIGNUP}
      </Typography>
      {isSignIn ? <SignInForm redirect={redirect}/> : <SignUpForm redirect={redirect} />}
      <Button onClick={toggleAuthMode}>
        {isSignIn? SWITCHSIGNUP : SWITCHSIGNIN}
      </Button>
    </Container>
  );
};

export default AuthComponent;
