
import { Button} from "@mui/material"
import toast from "react-hot-toast"
import { isValidImg } from "../../../util/images"
import { addQuiz } from "../../../API/quiz"
import { useNavigate } from "react-router-dom"
let initialQuiz={
  title:"",
  description:"",
  imageThumbnail:"",
  questions:new Array(10).fill(0).map(item=>{
    return {
      statment:"",
      options:["","","","",""],
      correct:"0",
      imageURI:"",
      reasoning:""
    }
  }),
  tags:[],
  grades:[],
  level:"",
  countries:[],
  material:"0"
}
function RenderCompletePage({quiz,setSearchParams,setQuiz}){
  const navigator=useNavigate();
    return <div style={{
      width:"100%",
      height:"70vh",
      display:"flex",
      alignItems:"center",
   
      justifyItems:"start",
      flexDirection:"column",
      gap:5
      }}>
        <div>
          <div>هذا الإختبار لا يخالف إتفاقية صانع المحتوى</div>
          <div>جميع الصور المستخدمة صالحة للاستخدام التجاري ولا تخالف حقوق الملكيةالفكرية</div>
          <div>محتوى الإختبار في أغلبه من تأليفي الشخصي ولا يمكن إرجاعه إلى شخص أو كيان معين</div>
          <div>الإختبار صحيح تماما من الناحية العلمية</div>
        </div>
         <Button onClick={async()=>{
          
          //"عنوان الإختبار"
          let title=quiz?.title?.trim()
           if(title=="" || title=="عنوان الإختبار"){
              toast.error("يرجى إضافة عنوان للإختبار")
              setSearchParams((old)=>{
                old.set("page","details");
                return old
              })
              return;
            }
          let description=quiz?.description?.trim();
          if(description?.length < 50 || description?.length > 150){
            toast.error("وصف الإختبار يجب أن يكون بين خمسين إلى مئة وخمسين حرف")
            setSearchParams((old)=>{
              old.set("page","details");
              return old
            })
            return;
          }
          if(!isValidImg(quiz?.imageThumbnail)){
            toast.error("يجب إضافة صورة بصيغة صحيحة للإختبار")
            setSearchParams((old)=>{
              old.set("page","details");
              return old
            })
            return;
          }
           
          let questions=quiz?.questions
          for(let i=0;i<10;i++){
            let question = questions[i];
            let statment=question?.statment?.trim();
            if(statment?.length<10){
             let errorMsg=`يجب إضافة نص للسؤال رقم ${i+1}`
             toast.error(errorMsg)
             setSearchParams((old)=>{
               old.set("page",i);
               return old
             });
             return;
  
            }
            let option1=question?.options[0]?.trim();
            if(option1==""){
              let errorMsg=`يجب إضافة الخيار الأول للسؤال رقم ${i+1}`
              toast.error(errorMsg)
              setSearchParams((old)=>{
                old.set("page",i);
                return old
              });
              return;
            }
            
            let option2=question?.options[1]?.trim();
            if(option2==""){
              let errorMsg=`يجب إضافة الخيار الثاني للسؤال رقم ${i+1}`
              toast.error(errorMsg)
              setSearchParams((old)=>{
                old.set("page",i);
                return old
              });
              return;
            }
  
            let imageURI=question?.imageURI?.trim();
            if(imageURI !="" && !isValidImg(imageURI)){
              let errorMsg=`صيغة رابط الصورة غير صحيحة للسؤال رقم ${i+1}`
              toast.error(errorMsg)
              setSearchParams((old)=>{
                old.set("page",i);
                return old
              });
              return;
            }
  
          }
  
  
  
  
  
  
            if(quiz?.tags?.length<5 ){
            toast.error("يجب إضافة خمسة وسوم على الأقل")
            setSearchParams((old)=>{
              old.set("page","tags");
              return old
            })
            return
           }
          
           try{
            const id=await addQuiz(quiz.questions,quiz.tags,quiz.title,quiz.description,quiz.imageThumbnail,quiz.grades,quiz.material,quiz.level)
            setQuiz(initialQuiz)
             navigator("/quiz/"+id)
           }catch(err){
            console.log(err)
           }
           
         }} variant="contained" color="success">إكمال الإختبار</Button>
        </div>
  }
  
export default  RenderCompletePage