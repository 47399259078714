import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"




export const logIn=async(username,password)=>{
    try{
    const {data}=await axios.post(serverURL+"/admin/adminauth",{
      username,password
    })
    return data
    }catch(err){

    }
}

export const verifyToken=async(token)=>{
    try{ 
        const {data}=await axios.post(serverURL+"/admin/verify",{
            token
        })
        return data

    }catch(err){
        return false
    }
}

export const addTeacher=async(username,password)=>{
    try{
        await axios.post(serverURL+"/admin/addteacher",{
             username,password
        },{
            headers:{
                Authorization:localStorage.getItem("token")
            }
        })
    }catch(err){

    }
}

export const getTeachersList=async()=>{
    try{
       const {data}=await axios.get(serverURL+"/admin/allteachers",{
        headers:{
            Authorization:localStorage.getItem("token")
        }
       })
       return data
    }catch(err){
        console.log(err)
        return []
    }
}

export const getUsersList=async()=>{
    try{
        const {data}=await axios.get(serverURL+"/admin/allusers",{
         headers:{
             Authorization:localStorage.getItem("token")
         }
        })
        return data
     }catch(err){
         console.log(err)
         return []
     }
}

export const updateTeacherActivity=async(id,new_activity)=>{
    try{
      await axios.put(serverURL+"/admin/activate_teacher/"+id,{
        new_activity
      },{
        headers:{
            Authorization:localStorage.getItem("token")
        }
      })
    }catch(err){

    }
}