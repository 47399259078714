import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"



export const addNotification=async(msg,icon,creators)=>{

    try{
      await axios.post(serverURL+"/notification/",{
         msg,icon,creators
      },{
            headers:{
                Authorization:localStorage.getItem("token")
            }

      })
    }catch(err){
       throw new Error("error adding notification")
    }

}

export const getTeacherNotification=async(type)=>{
    try{
      const {data}=await axios.get(serverURL+"/notification/teacher/all",{
        
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
        },
        params:{
            all:type
        }
    
      })
      console.log(data)
      return data
    }catch(err){
        console.log(err);
        return []
    }
}


export const readNotification=async(id)=>{
    try{
      await axios.post(serverURL+"/notification/read",{
        notification_id:id
      },{
        headers:{
            Authorization:window.localStorage.getItem("usertoken")
        }
      })
    }catch(err){
        console.log(err);
    }
}