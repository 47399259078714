import { signInWithPopup } from 'firebase/auth';
import { auth,provider } from '../util/firebase';
import axios from "axios"
import { updateToken } from '../util/token';
const serverURL =process.env.REACT_APP_SERVER_URL //"http://10.0.0.253:8000"


export const GoogleLogin = async() => {
  signInWithPopup(auth,provider).then((result)=>{
   console.log(result)
  }).catch(err=>{
    console.log(err);
  })
    /*try{

    let result= await  signInWithPopup(auth, provider)
    let user=result.user
    
    const {data}= axios.post(serverURL+"/auth/google",user)
    if(data && data.token){
      updateToken(data)
      return true
    }
    return false
      }catch(err){
         console.log(err)
         return false;
      }
         */
    
}