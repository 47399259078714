import { useParams } from "react-router-dom"
import { useState,useEffect } from "react"
import { generateQuizReport } from "../API/quiz"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getComplete } from "../API/complete"
const inital_options = {
    chart: {
        type: 'bar'
    },
    title: {
        text: 'Questions completed by students',
        align: 'left'
    },
    series: [{
        name: 'Question',
        data: []
    }]
}
const inital_options2 = {
    chart: {
        type: 'bar'
    },
    xAxis: {
        categories: ['Normal visit', 'student', 'facebook', 'youtube',"X","Whatsapp","Telegram","Instagram","LinkedIn","Source 1","Source 2","Source 3","Source 4"]
    },
    title: {
        text: 'Source of the students',
        align: 'left'
    },
    series: [{
        name: 'Question',
        data: []
    }]
}

const inital_options3 = {
    chart: {
        type: 'column', // Default to bar chart
      },
      title: {
        text: 'Number of Joins Over Time',
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
        },
        labels: {
          format: '{value:%Y-%m-%d}', // Format the date
        },
        dateTimeLabelFormats: {
            day: '%Y-%m-%d',
          },
      },
      yAxis: {
        title: {
          text: 'Number of Joins',
        },
      },
    series: []
}


const QuizReport=()=>{
const [complete,setComplete]=useState([])
const [options,setOptions]=useState(inital_options)
const [options2,setOptions2]=useState(inital_options2)
const [options3,setOptions3]=useState(inital_options3)
const {id}=useParams()


const loadReport=async()=>{
    const report =await generateQuizReport(id)

    setOptions((old)=>{
        return {...old,
            xAxis: {
            categories: new Array(report.q_count).fill(0).map((item,index)=>"Question "+(index+1))
        },series:[{
            name:"Total",
            data:report.questions.map(item=>item.count),
            color:"#5555FF"

        },
        {
            name:"Correct",
            data:report.questions.map(item=>item.correct),
            color:"#55FF55"

        },
        {
            name:"Incorrect",
            data:report.questions.map(item=>item.incorrect),
            color:"#FF5555"

        }
    
    ]}
    })
    setOptions2((old)=>{
        return {...old,series:[{
            name:"Platform",
            data:report.views

        }]}
    })
    setOptions3((old)=>{
        return {...old,series:[

            {
                type: 'line', // Line chart
                name: 'Joins (Line)',
                data: report.joins,
              }
        ]}
    })
}

const loadComplete=async()=>{
    try{
      const data=await getComplete(id);
      setComplete(data);
    }catch(err){

    }

  }
useEffect(()=>{
    loadComplete();
   loadReport();
},[])
return <>
 <HighchartsReact
    highcharts={Highcharts}
    options={options}
  />
 <HighchartsReact
    highcharts={Highcharts}
    options={options2}
  />
  <div>
    الطلاب الذين أكملوا الاختبار:
    <span>{complete.length}</span>
  </div>
  <HighchartsReact 
  highcharts={Highcharts}
  options={options3}
  />
</>
}

export default QuizReport