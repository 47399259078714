

import {  Paper } from "@mui/material"
const UsageAgreement=()=>{
 return <div
 style={{width:"100%",height:"100%",flexDirection:"column", display:"flex",alignItems:"center",justifyContent:"center",padding:5}}
 
 ><Paper sx={{width:"90%",textAlign:"right",direction:"rtl",padding:5}} elevation={3}>
   
     <h1>اتفاقية الاستخدام لمنصة اختبار</h1>
    <p>تعد هذه الاتفاقية بمثابة العقد بينك وبين منصة منصة اختبار، ويجب عليك قراءتها والموافقة عليها قبل استخدام المنصة. بمجرد إنشاء حسابك واستخدام المنصة، فإنك توافق على الالتزام بجميع الشروط والأحكام المذكورة أدناه.</p>

    <div class="section">
        <h2>1. إنشاء الحساب وتتبع الأنشطة</h2>
        <p>يتعين على المستخدمين إنشاء حساب شخصي على المنصة لتتبع أنشطتهم.</p>
        <p>يلتزم المستخدم بتقديم معلومات صحيحة ودقيقة عند إنشاء الحساب، والحفاظ على سرية بيانات الدخول الخاصة به.</p>
    </div>

    <div class="section">
        <h2>2. حقوق الملكية الفكرية</h2>
        <p>جميع المحتويات التي يتم إنشاؤها من قبل منشئي المحتوى العاملين لدى المنصة محمية بحقوق الطبع والنشر وتعود ملكيتها للمنصة.</p>
        <p>لا يجوز للمستخدم نسخ أو إعادة نشر أو توزيع أي محتوى من المنصة دون الحصول على إذن خطي مسبق من المنصة.</p>
    </div>

    <div class="section">
        <h2>3. الاختبارات المدفوعة</h2>
        <p>قد تحتوي المنصة على اختبارات مدفوعة، ولا يُسمح للمستخدم بأخذ لقطات شاشة لهذه الاختبارات أو مشاركة محتواها بأي شكل من الأشكال.</p>
        <p>يُمنع استخدام أجزاء من الاختبارات سواء لأغراض تجارية أو غير تجارية دون إذن مسبق.</p>
    </div>

    <div class="section">
        <h2>4. الروابط والمشاركة على وسائل التواصل الاجتماعي والمدونات</h2>
        <p>يُسمح للمستخدم بمشاركة الروابط الخاصة بالاختبارات على وسائل التواصل الاجتماعي والمدونات، شريطة عدم الكشف عن محتوى الاختبارات.</p>
    </div>

    <div class="section">
        <h2>5. نقطة الاتصال الرئيسية</h2>
        <p>في حال وجود أي استفسارات أو مشكلات، يمكن للمستخدمين التواصل مع المنصة عبر صفحة "اتصل بنا".</p>
    </div>

    <div class="section">
        <h2>6. الشروط والأحكام العامة</h2>
        <p>تحتفظ المنصة بالحق في تعديل أو تحديث هذه الاتفاقية في أي وقت دون إشعار مسبق. يُعتبر استمرار استخدامك للمنصة بعد إجراء أي تغييرات بمثابة موافقة منك على هذه التعديلات.</p>
        <p>تلتزم المنصة بحماية خصوصية بيانات المستخدمين وعدم مشاركتها مع أي طرف ثالث دون إذن مسبق.</p>
    </div>

    <div class="section">
        <h2>7. حدود المسؤولية</h2>
        <p>لا تتحمل المنصة أي مسؤولية عن الأضرار الناتجة عن استخدام المنصة أو عدم القدرة على الوصول إليها.</p>
        <p>يلتزم المستخدم بتعويض المنصة عن أي خسائر أو أضرار قد تنشأ نتيجة انتهاك المستخدم لشروط هذه الاتفاقية.</p>
    </div>

    <div class="section">
        <h2>8. القانون المعمول به</h2>
        <p>تخضع هذه الاتفاقية وتفسر وفقاً للقوانين المعمول بها في الولايات المتحدة الأمريكية.</p>
    </div>

    <p>يرجى قراءة هذه الاتفاقية بعناية. إذا كانت لديك أي استفسارات، يمكنك التواصل معنا عبر صفحة "اتصل بنا".</p>
 
    </Paper> 
 </div>
}

export default UsageAgreement