export  const updateToken=(data)=>{

    
    const event = new CustomEvent('localStorageChange', {
       detail: { key:"usertoken", newValue: {
           logged:data.logged,
           type:data.type,
           active:data.active,
           username:data.username
       }
        },
     });
     console.log(data);
     window.localStorage.setItem("usertoken",data.token)
     window.localStorage.setItem("user_type",data.type)
     window.dispatchEvent(event);
}

