import { useState,useEffect } from "react"
import { getTeacherQuizes } from "../API/quiz"
import CardComp from "../components/CardComp"
import ShareModal from "../components/ShareModal"

function QuestionThumbnail({user,quiz,setQuize,setSQuiz,setOpen,distanation}){
    return <CardComp setQuiz={setQuize} user={user} distanation={distanation} setOpen={setOpen} setSQuiz={setSQuiz} quiz={quiz}/>
    
 }
export default function Dashboard({user}){
   const [open,setOpen]=useState(false)
   const [s_quiz,setSQuiz]=useState("")
    const [quizes,setQuizes]=useState([])
  
    const loadTeacherQuizes=async()=>{
       try{
        const data=await getTeacherQuizes()
       
        setQuizes(data)
       }catch(err){
         console.log(err)
       }
    }
    
    useEffect(()=>{
       loadTeacherQuizes()
   
    },[])
    return <>
    <ShareModal quiz_id={s_quiz} {...{open,setOpen}}/>
    <div style={{

alignItems:"center",
justifyContent:"center",
display:"flex",
flexWrap:"wrap",
padding:"20px",
gap:"20px",
flexDirection:"row-reverse"
}}>
   {quizes.map(item=>{
        return <QuestionThumbnail setQuize={setQuizes} user={user} distanation={"quizreport"} setOpen={setOpen} setSQuiz={setSQuiz}  quiz={item}/>
    })}
    </div>
    </>
}