import axios from "axios";

const serverURL =process.env.REACT_APP_SERVER_URL 

export const submitAnswer=async(quiz_id,question_id,session_id,option,order,type)=>{
    try{
    const {data}=await axios.post(serverURL+"/submission",{
        quiz_id,question_id,option,session_id,order,type
    })
  
    return data
    }catch(err){

    }
}


export const generateReportAPI=async(quiz_id,session_id)=>{
    try{
      const {data}=await axios.post(serverURL+"/submission/report",{
        quiz_id,session_id
      })
     
      return data;
    }catch(err){

    }
}
