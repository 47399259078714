import axios from "axios"
const serverURL =process.env.REACT_APP_SERVER_URL

export const getSearchActivities=async()=>{
    try{
        const {data}=await axios.get(serverURL+"/searchActivities",{
            headers:{
                Authorization:window.localStorage.getItem("usertoken")
             }
        })
        
        return data;
    }catch(err){
        console.log(err);
        throw new Error(err.message)
    }
}