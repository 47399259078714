import toast from "react-hot-toast";
import { activateUser } from "../API/user";
import { useState } from "react";
import { Button } from "@mui/material";
import { sendAnotherCode } from "../API/user";
function ActivateAccountStudent({user,setUser}){
    const [code,setCode]=useState("")
 return <div style={{height:"80vh"}}>
    تم إرسال رمز تفعيل الحساب إلى الإيميل الإلكتروني الخاص بالحساب
    <form onSubmit={async(e)=>{
        
        e.preventDefault();
        if(code.length !=6){
            toast.error("الكود مكون من 6 أرقام")
            return;
        }
        try{
        await activateUser(code);
        setUser({...user,active:1})
        toast.success("تم تفعيل الحساب بنجاح")
        }catch(err){
            toast.error("حدث خطأ أثناء تفعيل الحساب")
        }
    }}>
        <input value={code} onChange={(e)=>{
            setCode(e.target.value)
        }} type="text" />
        <button color="info" variant="contained" type="submit">إدخال</button>
    </form>
    <button variant="contained" color="info" onClick={async()=>{
        try{
          await sendAnotherCode();
          toast.success("تم إرسال الكود مرة ثانية")
        }catch(err){
           toast.error("حدث مشكلة أثناء إرسال الكود")
        }
    }}>أرسل رمز حساب جديد</button>
 </div>
}
export default ActivateAccountStudent